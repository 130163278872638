<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  methods: {
    closeMenu() {
      $(".page-wrapper").toggleClass("toggled");
    },
  }
}
</script>


