<template>
  <div id="Admin" v-if="profile.role == 'admin'">
    <!--- Admin header --->
    <div id="text-box">
      <h2>Welcome to the <strong>Admin panel</strong>.</h2>
      <hr>
      <div class="text-box-description">
        <p>Here you can edit roles of other users.</p>
      </div>
    </div>
    <div class="container mt-5">
      <div class="d-flex justify-content-start align-items-center">
        <h3 class="d-inline float-left ml-5">Korisnici</h3>
      </div>

      <div class="table-responsive">
        <hr />
        <table class="table text-center table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th>Ime i prezime korisnika</th>
              <th>Email korisnika</th>
              <th>Rola</th>
            </tr>
          </thead>
          <tbody style="backgroundColor: rgba(220, 220, 220, 0.9);">
            <tr v-for="(profile, index) in profiles" v-bind:key="index">
              <td style="border-color: black !important;">
                <strong>{{ profile.name }} {{ profile.surname }}</strong>
              </td>
              <td style="border-color: black !important;">
                <strong>{{ profile.email }}</strong>
              </td>

              <td style="border-color: black !important;">
                <button class="btn btn-sm mr-2 mb-2" v-bind:class="{'btn-secondary': profile.role != 'admin', 'btn-success': profile.role == 'admin'}" @click="grantAdmin(profile)">
                  Admin
                </button>
                <button class="btn btn-sm mr-2 mb-2" v-bind:class="{'btn-secondary': profile.role != 'editor', 'btn-success': profile.role == 'editor'}" @click="grantEditor(profile)">
                  Editor
                </button>
                <button class="btn btn-sm mr-2 mb-2" v-bind:class="{'btn-secondary': profile.role != 'viewer', 'btn-success': profile.role == 'viewer'}" @click="grantViewer(profile)">
                  Viewer
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { fb, db } from "../../backend/firebase.js";

export default {
  name: "Admin",
  data() {
    return {
      profile: {
        name: null,
        surname: null,
        email: null,
        password: null,
        kod: null,
        inbox: [],
        id: null,
        action: "login",
        role: null
      },
    };
  },
  methods: {
    grantAdmin(user) {
      user.role = "admin"
      this.$firestore.profiles.doc(user.id).update(user);
      Toast.fire({
        icon: "success",
        title: `Granted an admin role to ${user.name} ${user.surname}`
      });
    },
    grantEditor(user) {
      user.role = "editor"
      this.$firestore.profiles.doc(user.id).update(user);
      Toast.fire({
        icon: "success",
        title: `Granted an editor role to ${user.name} ${user.surname}`
      });
    },
    grantViewer(user) {
      user.role = "viewer"
      this.$firestore.profiles.doc(user.id).update(user);
      Toast.fire({
        icon: "success",
        title: `Granted a viewer role to ${user.name} ${user.surname}`
      });
    }
  },
  firestore() {
    const user = fb.auth().currentUser;
    return {
      profile: db.collection("profiles").doc(user.uid),
      profiles: db.collection("profiles")
    };
  },
};
</script>


<style scoped src="./admin.css">
</style>