<template>
    <div id="AddTicket">
        <div
            class="modal fade"
            id="addTicketModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            Add a new ticket
                        </h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="exampleInputPassword1">Ticket Title <font color="#FF4136;">*</font></label>
                            <input
                            type="text"
                            v-model="ticket.title"
                            class="form-control"
                            placeholder="Ticket Title"
                            />
                        </div>
                        <div class="form-group">
                            <label for="exampleInputPassword1"
                            >Ticket Description <font color="#FF4136;">*</font></label
                            >
                            <input
                            type="text"
                            v-model="ticket.description"
                            class="form-control"
                            placeholder="Ticket Description"
                            />
                        </div>
                        <hr />
                        <p class="text-bold">Ticket's status</p>
                        <hr />
                        <div class="list-group">
                            <a href="#" class="list-group-item list-group-item-action" v-for="(statusOption, index) in statusOptions" :key="index" @click="assignStatus(statusOption)" v-bind:class="{ 'active': ticket.status == statusOption }">
                                {{ statusOption }}
                            </a>
                        </div>
                        <hr />
                        <p class="text-bold">Ticket's priority</p>
                        <hr />
                        <div class="list-group">
                            <a href="#" class="list-group-item list-group-item-action" v-for="(priorityOption, index) in priorityOptions" :key="index" @click="assignPriority(priorityOption)" v-bind:class="{ 'active': ticket.priority == priorityOption }">
                                {{ priorityOption }}
                            </a>
                        </div>
                        <hr />
                        <p class="text-bold">Assigned users:</p>
                        <ul class="list-group">
                            <li
                            v-for="user in ticket.assignedTo"
                            v-bind:key="user.id"
                            class="list-group-item"
                            >
                            {{ user.email
                            }}<i
                                @click="unassignUser(user)"
                                class="fas fa-times float-right"
                                style="font-size: 24px"
                            ></i>
                            </li>
                        </ul>
                        <hr />
                        Assign the ticket to a specific person:
                        <select
                            class="form-control w-100"
                            id="exampleFormControlSelect1"
                            v-model="currentAssignee"
                        >
                            <option
                            v-for="user in profiles"
                            v-bind:key="user.id"
                            v-bind:value="user"
                            >
                            {{ user.email }}
                            </option>
                        </select>
                        <button
                            class="btn btn-primary mt-2 mb-2"
                            @click="assignUser"
                            v-if="currentAssignee != null"
                        >
                            Assign user
                        </button>
                        <hr />
                        <font color="#FF4136;">*</font>
                        <datepicker
                            :disabled-dates="datepickerState.disabledDates"
                            v-model="ticket.deadline"
                            placeholder="Deadline"
                        ></datepicker>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                        <button class="btn btn-info" @click="addNewTicket">
                            Add Ticket
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
import { fb, db } from "../../backend/firebase.js";
import Datepicker from "vuejs-datepicker";
const dateFormat = require("dateformat");

export default {
    name: "AddTicket",
    components: {
        Datepicker,
    },
    data() {
        return {
            datepickerState: {
                disabledDates: {
                to: new Date(),
                },
            },
            ticket: {
                id: null,
                title: null,
                description: null,
                status: "To do",
                priority: "Low",
                deadline: null,
                assignedTo: [],
                postedBy: null,
                time: null
            },
            statusOptions: ["To do", "On hold", "In progress", "Pending review", "QA pass", "Done"],
            priorityOptions: ["Low", "Medium", "High"],
            currentAssignee: null,
            profiles: [],
        }
    },
    methods: {
        assignUser() {
            this.ticket.assignedTo = [...this.ticket.assignedTo, this.currentAssignee];
            this.profiles = this.profiles.filter((profile) => {
                return profile.email != this.currentAssignee.email;
            });
            this.currentAssignee = null;
        },
        unassignUser(user) {
            this.ticket.assignedTo = this.ticket.assignedTo.filter((myUser) => {
                return myUser.email != user.email;
            });
            this.profiles = [...this.profiles, user];
        },
        assignStatus(status) {
            this.ticket.status = status;
        },
        assignPriority(priority) {
            this.ticket.priority = priority;
        },
        generateID(length) {
            var ret = "";
            while (ret.length < length) {
                ret += Math.random().toString(16).substring(2);
            }
            return ret.substring(0, length);
        },
        addNewTicket() {
            Swal.fire({
                title: "Are you sure you want to add this ticket?",
                text: "The ticket will be visible to everyone on VirtualOffice.",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, I am.",
                cancelButtonText: "No, I am not.",
            }).then((result) => {
                if (result.value) {
                    if(this.ticket.title == null || this.ticket.description == null || this.ticket.deadline == null) {
                        Toast.fire({
                            icon: "error",
                            title: "Please enter all the mandatory fields of the ticket!",
                        });
                    } else {
                        this.ticket.deadlineDate = dateFormat(this.ticket.deadline, "fullDate");
                        this.ticket.time = new Date();
                        this.ticket.datePosted = dateFormat(this.ticket.time, "fullDate");
                        var id = this.generateID(26);
                        this.ticket.id = id;
                        this.ticket.postedBy = this.profile;
                        this.ticket.assignedTo.forEach(assignee => {
                            assignee.startTime = null;
                            assignee.endTime = null;
                        })
                        db.collection("tickets").doc(id).set(this.ticket);
                        $("#addTicketModal").modal("hide");
                        this.resetTicketInfo();
                        Toast.fire({
                            icon: "success",
                            title: "New ticket added successfully!",
                        });              
                    } 
                }
            });  
        },
        resetTicketInfo() {
            this.ticket = {
                id: null,
                title: null,
                description: null,
                status: "To do",
                priority: "Low",
                deadline: null,
                assignedTo: [],
                postedBy: null,
                time: null
            };
        },
    },
    firestore() {
    const user = fb.auth().currentUser;
    return {
        tickets: db.collection("tickets"),
        profile: db.collection("profiles").doc(user.uid),
        profiles: db.collection("profiles")
    };
  },
}
</script>
