<template>
  <div id="Gallery">
    <!--- Image modal --->
    <div class="modal fade" id="imageModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body d-flex justify-content-center">
            <div :style="{ backgroundImage: `url(${currentImage})`,}" id="modalImage"
            ></div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--- Gallery header --->
    <div id="text-box">
      <h2>Welcome to the <strong>Gallery</strong>!</h2>
      <hr>
      <div class="text-box-description" v-if="profile.role != 'viewer'">
        <p>Share your images with other colleagues!</p>
        <label class="btn btn-default btn-sm center-block btn-file ml-4">
          <i class="fa fa-upload fa-2x" aria-hidden="true"></i>
          <input type="file" style="display: none;" @change="addFile">
        </label>
      </div>
    </div>
    <!--- Gallery images --->
    <div id="gallery-flex">
        <div id="gallery-box" v-for="(file, index) in files" v-bind:key="index">
            <div id="gallery-title" data-toggle="modal" data-target="#imageModal" @click="seeImage(file)">
                Posted by: <strong>{{  file.name + ' ' + file.surname }}</strong>
            </div>
            <div id="gallery-description" :style="{backgroundImage: `url(${file.url})`}" data-toggle="modal" data-target="#imageModal" @click="seeImage(file)">
               
            </div>
            <div id="delete-box">
                <p data-toggle="modal" data-target="#imageModal" @click="seeImage(file)">{{ file.date }}</p>
                <button v-if="profile.role != 'viewer'" @click="deleteImage(file)" class="btn btn-secondary">Delete</button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { fb, db } from "../../backend/firebase.js";
const dateFormat = require("dateformat");

export default {
  name: "Gallery",
  data() {
    return {
      currentImage: null,
      profile: {
        name: null,
        surname: null,
        email: null,
      },
      files: [],
      file: {
        url: null,
        id: null,
        name: null,
        surname: null,
        timestamp: null,
        date: null
      }
    };
  },
  methods: {
    addFile(e) {
      //console.log(e.target.files[0]);
      if (e.target.files[0]) {
        let file = e.target.files[0];
        var storageRef = fb
          .storage()
          .ref("images/" + Math.random() + "_" + file.name);
        let fileUpload = storageRef.put(file);
        fileUpload.on(
          "state_changed",
          (snapshot) => {},
          (error) => {
            // Handle unsuccessful uploads
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            fileUpload.snapshot.ref.getDownloadURL().then((downloadURL) => {
              var id = this.generateID(26);
              this.file.url = downloadURL;
              this.file.id = id;
              this.file.name = this.profile.name
              this.file.surname = this.profile.surname
              this.file.timestamp = new Date();
              this.file.date = dateFormat(this.file.timestamp, "fullDate")
              db.collection("vof-files").doc(id).set(this.file);
              this.file = {
                url: null,
                id: null,
                name: null,
                surname: null,
                timestamp: null,
                date: null
              }
              Toast.fire({
                icon: "success",
                title: "Image uploaded successfully!",
              });
            });
          }
        );
      }
    },
    deleteImage(file){
      Swal.fire({
        title: "Are you sure you want to delete this image?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, I am.",
        cancelButtonText: "No, I am not.",
      }).then((result) => {
        if (result.value) {
          db.collection("vof-files").doc(file.id).delete();
          Toast.fire({
            icon: "success",
            title: "Image deleted!",
          });
        }
      });
    },
    seeImage(file) {
      this.currentImage = file.url
    },
    generateID(length) {
      var ret = "";
      while (ret.length < length) {
        ret += Math.random().toString(16).substring(2);
      }
      return ret.substring(0, length);
    },
  },
  firestore() {
    const user = fb.auth().currentUser;
    return {
      profile: db.collection("profiles").doc(user.uid),
      files: db.collection("vof-files").orderBy("timestamp", "desc")
    };
  },
};
</script>


<style scoped src="./gallery.css">
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
</style>