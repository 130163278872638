<template>
  <div id="Login">
    <div
      class="modal fade"
      id="login"
      tabindex="-1"
      role="dialog"
      aria-labelledby="loginTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <ul class="nav nav-fill nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="pills-home-tab"
                  data-toggle="pill"
                  href="#pills-login"
                  role="tab"
                  aria-controls="pills-login"
                  aria-selected="true"
                >Sign in</a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="pills-register-tab"
                  data-toggle="pill"
                  href="#pills-register"
                  role="tab"
                  aria-controls="pills-register"
                  aria-selected="false"
                >Register</a>
              </li>
            </ul>

            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-login"
                role="tabpanel"
                aria-labelledby="pills-login-tab"
              >
                <h5 class="text-center">Enter your personal information</h5>
                <div class="form-group">
                  <label for="exampleInputEmail1">Email address <font color="#FF4136;">*</font></label>
                  <input
                    type="email"
                    v-model="email"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Your email address"
                  />
                  <small
                    class="form-text text-muted"
                  >You private information is not available to anyone but you.</small>
                </div>
                <div class="form-group">
                  <label for="exampleInputPassword1">Password <font color="#FF4136;">*</font></label>
                  <input
                    type="password"
                    @keyup.enter="login"
                    v-model="password"
                    class="form-control"
                    id="exampleInputPassword1"
                    placeholder="Your password"
                  />
                </div>

                <div class="form-group">
                  <button class="btn btn-primary" @click="login">Sign in</button>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="pills-register"
                role="tabpanel"
                aria-labelledby="pills-register-tab"
              >
                <h5 class="text-center">Create a new profile</h5>
                <div class="form-group">
                  <label for="name">Enter your name <font color="#FF4136;">*</font></label>
                  <input
                    type="text"
                    v-model="userInfo.name"
                    class="form-control"
                    id="name"
                    placeholder="Your name"
                  />
                </div>
                <div class="form-group">
                  <label for="name">Enter your surname <font color="#FF4136;">*</font></label>
                  <input
                    type="text"
                    v-model="userInfo.surname"
                    class="form-control"
                    id="surname"
                    placeholder="Your surname"
                  />
                </div>
                <div class="form-group">
                  <label for="email">Enter your email address <font color="#FF4136;">*</font></label>
                  <input
                    type="email"
                    v-model="userInfo.email"
                    class="form-control"
                    id="email"
                    aria-describedby="emailHelp"
                    placeholder="Your email address"
                  />
                </div>
                <div class="form-group">
                  <label for="password">Enter your password <font color="#FF4136;">*</font></label>
                  <input
                    type="password"
                    v-model="userInfo.password"
                    class="form-control"
                    id="password"
                    placeholder="Your password"
                  />
                </div>
                <div class="form-group">
                  <label for="password">Enter your company's code <font color="#FF4136;">*</font></label>
                  <input
                    type="text"
                    v-model="userInfo.kod"
                    class="form-control"
                    id="kod"
                    placeholder="Your company's code"
                  />
                </div>
                <p class="mt-2 mb-2" style="font-size: 14px">
                  Your approval code: {{ potvrdniKod }}
                </p>
                <B>
                  <p class="mt-2" v-if="postojiError">{{ errorMessage }}</p>
                </B>

                <div class="form-group">
                  <button class="btn btn-primary" @click="signUp">Register</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fb, db } from '../../backend/firebase'

export default {
  name: "Login",
  data() {
    return {
    potvrdniKod: null,
    postojiError: false,
    error: null,
    trazeniKod: "",
    userInfo: {
        name: null,
        surname: null,
        email: null,
        password: null,
        kod: null,
        inbox: [],
        id: null,
        action: "login",
        role: "viewer"
    },
    email: null,
    password: null,
    };
  },
  computed: {
    errorMessage: function () {
      if (this.potvrdniKod == "") {
        return null;
      } else if (!this.trazeniKod.includes(this.potvrdniKod)) {
        return "Entered code is invalid!";
      }
    },
  },
  methods: {
    login() {
      fb.auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          $( "div" ).remove( ".show" );
          this.$router.replace("main/frontpage");
        })
        .catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          if (errorCode === "auth/wrong-password") {
            alert("Wrong password.");
          } else {
            alert(errorMessage);
          }
          console.log(error);
        });
    },
    signUp() {
        if(this.userInfo.name == null || this.userInfo.surname == null || this.userInfo.email == null || this.userInfo.password == null || this.userInfo.kod == null) {
          Toast.fire({
              icon: "error",
              title: "Please enter all the mandatory fields of the registration!",
          });
        } else {
            this.trazeniKod = this.trazeniKod[0].kod
            if(this.userInfo.kod == this.trazeniKod) {
              fb.auth()
                .createUserWithEmailAndPassword(
                this.userInfo.email,
                this.userInfo.password
                )
                .then((user) => {
                this.userInfo.id = user.user.uid;
                this.userInfo.action = "register";
                $("#login").modal("hide");
                db.collection("profiles")
                    .doc(user.user.uid)
                    .set(this.userInfo)
                    .then(function () {
                    console.log("Document successfully written!");
                    })
                    .catch(function (error) {
                    console.error("Error writing document: ", error);
                    });
                this.$router.replace("main/frontpage");
                })
                .catch(function (error) {
                var errorCode = error.code;
                var errorMessage = error.message;
                if (errorCode == "auth/weak-password") {
                    alert("The password is too weak.");
                } else {
                    alert(errorMessage);
                }
                console.log(error);
              });
          } else {
            this.postojiError = true;
            this.error = "Entered code is invalid!";
          }
        }
      }
  },
  firestore() {
    return {
      trazeniKod: db.collection("company")
    }
  }
};
</script>