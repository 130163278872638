<template>
  <div id="MyProfile">
    <div id="text-box">
      <h2><strong>Profile settings</strong></h2>
      <hr>
      <div class="text-box-description">
        <p>Edit your personal information.</p>
      </div>
    </div>
      <div class="row d-flex align-items-left">
        <div class="col-md-12">
          <div class="profile-input profile-image">
            <label class="btn btn-default btn-sm center-block btn-file ml-4">
              <div id="profile-image" :style="{backgroundImage: `url(${profile.profileImage})`}">
                <i class="fas fa-cloud-upload-alt profile-image-icon"></i>
              </div>
              <input type="file" style="display: none;" @change="addProfileImage">
            </label>
          </div>
        </div>
        <div class="col-md-12 ">
          <div class="form-group profile-input">
            <input
              type="text"
              v-model="profile.name"
              placeholder="Name"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group profile-input">
            <input
              type="text"
              v-model="profile.surname"
              placeholder="Surname"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group profile-input">
            <input
              type="text"
              v-model="profile.address"
              placeholder="Address"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group profile-input">
            <input
              type="text"
              v-model="profile.phone"
              placeholder="Telephone number"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group profile-input">
            <input
              type="text"
              v-model="profile.postCode"
              placeholder="ZIP Code"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group profile-input">
            <input
              type="submit"
              @click="resetPassword"
              value="Reset password"
              class="btn btn-success w-100"
            />
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group profile-input">
            <input
              type="submit"
              @click="updateProfile"
              value="Save changes"
              class="btn btn-primary w-100"
            />
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { fb, db } from "../../backend/firebase.js";
export default {
  name: "MyProfile",
  data() {
    return {
      profile: {
        name: null,
        surname: null,
        phone: null,
        address: null,
        postcode: null,
        profileImage: null
      },
      selectedPhoto: null
    };
  },
  firestore() {
    const user = fb.auth().currentUser;
    return {
      profile: db.collection("profiles").doc(user.uid),
    };
  },
  methods: {
    resetPassword() {
      const user = fb.auth();
      user
        .sendPasswordResetEmail(user.currentUser.email)
        .then(() => {
          Toast.fire({
            icon: "success",
            title: "Mail for the password reset sent successfully!",
          });
        })
        .catch((error) => {
          // An error happened.
        });
    },
    updateProfile() {
      this.$firestore.profile.update(this.profile);
      this.selectedPhoto = null;
      Toast.fire({
        icon: "success",
        title: "Profile updated successfully!",
      });
    },
    addProfileImage(e) {
      this.selectedPhoto = e.target.files[0].name;
      if (e.target.files[0]) {
        let file = e.target.files[0];
        var storageRef = fb
          .storage()
          .ref("profileimages/" + Math.random() + "_" + file.name);
        let fileUpload = storageRef.put(file);
        fileUpload.on(
          "state_changed",
          (snapshot) => {},
          (error) => {
            // Handle unsuccessful uploads
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            fileUpload.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.profile.profileImage = downloadURL;
            });
          }
        );
      }
    },
  },
};
</script>

<style scoped src="./my-profile.css"> 
</style>

