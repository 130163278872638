<template>
  <div id="Quiz">
    <div class="quiz-wrapper">
      <div id="text-box">
        <h2>Welcome to the <strong>Quiz room</strong>!</h2>
        <hr>
        <div class="text-box-description">
          <p>Here you have a chance to compete with your teammates by playing a short IT quiz!</p>
        </div>
      </div>
      <div class="main">
        <ul class="leaderboard user-list list-group">
            <li class="quiz-subtitle" style="list-style: none;" v-once><b>Leaderboard</b></li>
            <br>
            <div v-if="results.length == 0" class="text-box-description">
              <p>Leaderboard history is currently empty.</p>
            </div>
            <li class="user-item list-group-item" v-for="(competitor, index) in results" v-bind:key="index"> <b>{{ competitor.user.name }} {{ competitor.user.surname }}</b> - {{ competitor.correct }}/{{ competitor.total }}</li>
        </ul>
        <div class="questions">
          <p class="quiz-subtitle" v-once><b>Questions</b></p>
          <ul
            id="qst-list"
            v-for="(question, index) in info"
            v-bind:key="index"
            style="list-style: none"
          >
            
            <li class="question-wrapper" v-bind:class="{ 'blocked': question.blocked == true }">
              <span id="qst-text" v-html="question.question"></span>
              <div class="btns">
                  <i v-bind:class="{ 'hidden-icon': question.blocked == true &&  question.correct == false, 'hidden-icon-correct': question.blocked == true &&  question.correct == true}" @click="checkTrue(question)" class="fas fa-check-circle answer-icon answer-icon-true"></i>
                  <i v-bind:class="{ 'hidden-icon hidden-icon-incorrect': question.blocked == true &&  question.correct == true, 'hidden-icon-incorrect': question.blocked == true &&  question.correct == false}" @click="checkFalse(question)" class="fas fa-times-circle answer-icon answer-icon-false"></i>
              </div>
            </li>
            <hr>
          </ul>
          <p class="result-text" v-if="counter == info.length"><b>Score:</b> {{ this.correctAnswers + "/" + this.info.length }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
import { fb, db } from "../../backend/firebase.js";

export default {
  name: "Quiz",
  data() {
    return {
      info: null,
      questions: [],
      correctAnswers: 0,
      counter: 0,
      clicked: [],
      highscore: 0,
      results: []
    };
  },
  methods: {
    generateID(length) {
        var ret = "";
        while (ret.length < length) {
            ret += Math.random().toString(16).substring(2);
        }
        return ret.substring(0, length);
    },
    checkTrue(question) {
      question.blocked = true;
      if (question.correct_answer == "True") {
        this.correctAnswers++;
        if(this.highscore < this.correctAnswers){
          this.highscore++;
        }
        this.counter++;
        question.correct = true
        if(this.counter == this.info.length) {
          var user = fb.auth().currentUser;
          var resultId = this.generateID(26);
          db.collection("profiles").doc(user.uid).get().then(snapshot => {
              if(snapshot.data()) {
                db.collection("quizresults").doc(resultId).set({
                  user: snapshot.data(),
                  id: resultId,
                  total: this.info.length,
                  correct: this.correctAnswers
                });
              }
          })
          Swal.fire({
            title: `You answered ${this.correctAnswers} out of ${this.info.length} questions correctly!`,
            text: "Do you want to play again?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, I would love to!',
            cancelButtonText: "No, I don't.",
          }).then((result) => {
            if (result.isConfirmed) {
              this.playAgain();
            }
          })
        }
      } else {
        question.correct = false
        this.counter++;
        if(this.counter == this.info.length) {
          var user = fb.auth().currentUser;
          var resultId = this.generateID(26);
          db.collection("profiles").doc(user.uid).get().then(snapshot => {
              if(snapshot.data()) {
                db.collection("quizresults").doc(resultId).set({
                  user: snapshot.data(),
                  id: resultId,
                  total: this.info.length,
                  correct: this.correctAnswers
                });
              }
          })
          Swal.fire({
            title: `You answered ${this.correctAnswers} out of ${this.info.length} questions correctly!`,
            text: "Do you want to play again?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, I would love to!',
            cancelButtonText: "No, I don't.",
          }).then((result) => {
            if (result.isConfirmed) {
              this.playAgain();
            }
          })
        }
      }
    },
    checkFalse(question) {
      question.blocked = true;
      if (question.correct_answer == "False") {
        question.correct = true
        this.correctAnswers++;
        if(this.highscore < this.correctAnswers){
          this.highscore++;
        }
        this.counter++;
        if(this.counter == this.info.length) {
          var user = fb.auth().currentUser;
          var resultId = this.generateID(26);
          db.collection("profiles").doc(user.uid).get().then(snapshot => {
              if(snapshot.data()) {
                db.collection("quizresults").doc(resultId).set({
                  user: snapshot.data(),
                  id: resultId,
                  total: this.info.length,
                  correct: this.correctAnswers
                });
              }
          })
          Swal.fire({
            title: `You answered ${this.correctAnswers} out of ${this.info.length} questions correctly!`,
            text: "Do you want to play again?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, I would love to!',
            cancelButtonText: "No, I don't.",
          }).then((result) => {
            if (result.isConfirmed) {
              this.playAgain();
            }
          })
        }
      } else {
        question.correct = false
        this.counter++;
        if(this.counter == this.info.length) {
          var user = fb.auth().currentUser;
          var resultId = this.generateID(26);
          db.collection("profiles").doc(user.uid).get().then(snapshot => {
              if(snapshot.data()) {
                db.collection("quizresults").doc(resultId).set({
                  user: snapshot.data(),
                  id: resultId,
                  total: this.info.length,
                  correct: this.correctAnswers
                });
              }
          })
          Swal.fire({
            title: `You answered ${this.correctAnswers} out of ${this.info.length} questions correctly!`,
            text: "Do you want to play again?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, I would love to!',
            cancelButtonText: "No, I don't.",
          }).then((result) => {
            if (result.isConfirmed) {
              this.playAgain();
            }
          })
        }
      }
    },
    playAgain() {
      this.info= null
      this.questions= []
      this.progress= null
      this.correctAnswers= 0
      this.counter= 0
      this.clicked= []
      this.highscore= 0
      this.getQuestions();
    },
    getQuestions(){
       axios.get("https://opentdb.com/api.php?amount=10&category=18&difficulty=easy&type=boolean").then((response) => {
        this.info = response.data.results;
        this.info.forEach(question => {
          question.correct = null;
          question.blocked = false;
        })
      });
    }
  },
  mounted() {
    this.getQuestions();
  },
  firestore() {
    return {
      results: db.collection("quizresults").orderBy("correct", "desc").limit(10),
    };
  },
};
</script>

<style scoped src="./quiz.css">
</style>