<template>
  <div id="Main">
    <!--- Adding new rooms --->
    <div
      class="modal fade"
      id="addRoomModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add a new room</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="exampleInputPassword1">Room name <font color="#FF4136;">*</font></label>
              <input
                type="text"
                v-model="newRoom.ime"
                class="form-control"
                placeholder="Enter room's name"
              />
            </div>
          </div>
          
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button class="btn btn-info" @click="addNewRoom">
              Add a new room
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--- Side menu --->
    <div class="page-wrapper toggled">
      <a
        id="show-sidebar"
        @click="closeMenu"
        href="#"
      >
        <i class="fas fa-bars"></i>
      </a>
      <nav id="sidebar" class="sidebar-wrapper" @click="closeMenu">
        <div
          class="sidebar-content sidebar-main"
        >
          <!-- sidebar-brand  -->
          <div class="sidebar-item sidebar-brand">
            <div id="sidebar-logo">
              <svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" width="38.371395mm" height="26.93634mm" viewBox="0 0 38.371395 26.93634" version="1.1" id="svg8" inkscape:version="1.0.2-2 (e86c870879, 2021-01-15)" sodipodi:docname="Vofina Logo.svg">
                <defs id="defs2"></defs>
                <sodipodi:namedview id="base" pagecolor="#ffffff" bordercolor="#666666" borderopacity="1.0" inkscape:pageopacity="0.0" inkscape:pageshadow="2" inkscape:zoom="3.8963964" inkscape:cx="45.339226" inkscape:cy="74.384798" inkscape:document-units="mm" inkscape:current-layer="layer1" inkscape:document-rotation="0" showgrid="false" inkscape:snap-nodes="true" inkscape:window-width="1920" inkscape:window-height="1017" inkscape:window-x="-8" inkscape:window-y="-8" inkscape:window-maximized="1"></sodipodi:namedview>
                <metadata id="metadata5">
                  <rdf:rdf>
                    <cc:work rdf:about="">
                      <dc:format>image/svg+xml</dc:format>
                      <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage"></dc:type>
                      <dc:title></dc:title>
                    </cc:work>
                  </rdf:rdf>
                </metadata>
                <g inkscape:label="Layer 1" inkscape:groupmode="layer" id="layer1" transform="translate(-30.528926,-35.838464)">
                  <g id="g914">
                    <path style="fill:#1b1b1b;fill-opacity:1;stroke:none;stroke-width:0.1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="m 30.528926,43.097789 h 4.187968 l 5.418928,15.641646 0.986039,-2.752334 c 0.959044,0.959044 1.735555,2.056485 2.588385,3.533631 l -1.199357,3.248603 h -4.767284 z" id="path847" sodipodi:nodetypes="cccccccc"></path>
                    <path style="fill:#1b1b1b;fill-opacity:1;stroke:none;stroke-width:0.05;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="m 62.188516,62.766304 3.952248,0.0085 V 50.242283 h 2.759559 v -3.180509 h -2.736172 c 0,-2.141974 -0.03013,-3.028497 2.549083,-3.028497 V 40.70076 c -4.399907,0 -6.501331,1.330584 -6.501331,6.361014 h -1.777344 v 3.180509 h 1.753957 z" id="path919" sodipodi:nodetypes="ccccccccccccc"></path>
                    <path id="path849" style="fill:#60b4d5;fill-opacity:1;fill-rule:evenodd;stroke:none;stroke-width:0.0968336;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="M 60.534559,51.333508 A 10.235781,10.478297 0 0 1 50.298779,61.811805 10.235781,10.478297 0 0 1 40.062998,51.333508 10.235781,10.478297 0 0 1 50.298779,40.85521 10.235781,10.478297 0 0 1 60.534559,51.333508 Z"></path>
                    <g id="g892" transform="translate(-47.382745,-0.56788205)" style="stroke:none;stroke-opacity:1">
                      <path style="fill:#e0e0d1;fill-opacity:1;stroke:none;stroke-width:0.07;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="m 96.070223,54.918093 h 3.236782 c -0.118836,0.87685 -0.0136,1.341912 0.527316,1.946357 l -4.351432,0.0086 c 0.583621,-0.566427 0.70286,-1.10444 0.587334,-1.954931 z" id="path851" sodipodi:nodetypes="ccccc"></path>
                      <path style="opacity:0.3;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.05;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="m 94.464003,57.433609 h 1.012681 l 0.08852,-0.09523 4.204827,0.0043 0.0736,0.08185 h 1.084949 c 0.30372,0 0.60853,0.27649 0.60853,0.660859 0,0.43119 -0.29355,0.68814 -0.64617,0.68814 h -6.451176 c -0.306762,0 -0.632874,-0.257512 -0.632874,-0.654796 0,-0.349554 0.300866,-0.685123 0.657128,-0.685123 z" id="path853-9" sodipodi:nodetypes="sccccssssss"></path>
                      <path style="fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.05;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="m 94.468577,56.864059 h 1.012681 l 0.08852,-0.09523 4.204822,0.0043 0.07358,0.08185 h 1.08495 c 0.30372,0 0.60853,0.27649 0.60853,0.660859 0,0.43119 -0.29355,0.68814 -0.64617,0.68814 h -6.451172 c -0.306762,0 -0.632874,-0.257512 -0.632874,-0.654796 0,-0.349554 0.300866,-0.685123 0.657128,-0.685123 z" id="path853" sodipodi:nodetypes="sccccssssss"></path>
                      <path style="opacity:0.3;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.0400601;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="m 91.322656,53.664876 12.717734,0.01651 v 0.649939 c 0,0.504862 -0.42109,1.188629 -1.10666,1.188629 H 92.519757 c -0.757182,0 -1.192668,-0.647351 -1.192668,-1.238212 z" id="path863" sodipodi:nodetypes="ccccccc"></path>
                      <path style="fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.05;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="m 92.447294,45.029254 h 10.520736 c 0.53836,0 1.07196,0.584298 1.07196,1.095996 v 7.548358 c 0,0.756483 -0.56968,1.253061 -1.30841,1.253061 H 92.67297 c -0.711116,0 -1.348597,-0.521778 -1.348597,-1.31534 v -7.481845 c 0.01018,-0.55631 0.562747,-1.061061 1.122921,-1.10023 z" id="path855" sodipodi:nodetypes="ccccccccc"></path>
                      <path style="fill:#4f5d73;fill-opacity:1;stroke:none;stroke-width:0.05;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="m 92.301839,46.293272 c 0,-0.158021 0.06831,-0.249288 0.225791,-0.249288 l 3.756823,0.0084 4.394437,7.793905 h -8.160355 c -0.131304,0 -0.195001,-0.06505 -0.195001,-0.195529 z" id="path857" sodipodi:nodetypes="ccccccc"></path>
                      <path style="fill:#727d8f;fill-opacity:1;stroke:none;stroke-width:0.05;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="m 96.095946,46.052316 h 6.597894 c 0.21925,0 0.33122,0.118678 0.33225,0.3494 v 7.138066 c 0,0.207906 -0.0818,0.307602 -0.30118,0.307602 l -2.28824,-2e-6 z" id="path859" sodipodi:nodetypes="ccccccc"></path>
                    </g>
                    <g id="g917" style="fill:#ff8686;fill-opacity:1" transform="translate(12.655784,6.6071898)">
                      <circle style="opacity:1;fill:#ff8686;fill-opacity:1;fill-rule:evenodd;stroke:none;stroke-width:0.0377143;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" id="path905" cx="43.255486" cy="34.713722" r="0.48233795"></circle>
                      <path style="fill:#ff8686;fill-opacity:1;stroke:none;stroke-width:0.05;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="m 42.901769,33.617495 c 0,0 0.550453,-0.169904 0.976371,0.076 0.415664,0.239984 0.549571,0.754203 0.549571,0.754203 l 0.906211,-0.274788 c 0,0 -0.191032,-0.843851 -0.947137,-1.280388 -0.641159,-0.370173 -1.648716,-0.122777 -1.648716,-0.122777 z" id="path907" sodipodi:nodetypes="csccscc"></path>
                      <path style="opacity:1;fill:#ff8686;fill-opacity:1;stroke:none;stroke-width:0.05;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="m 42.358137,31.129882 0.227307,0.848323 c 0,0 1.246353,-0.366839 2.302771,0.243084 0.974455,0.562601 1.248503,1.736328 1.248503,1.736328 l 0.961565,-0.257651 c 0,0 -0.37131,-1.509939 -1.763583,-2.313768 -1.517213,-0.875964 -2.976563,-0.256316 -2.976563,-0.256316 z" id="path909" sodipodi:nodetypes="ccsccsc"></path>
                      <path style="fill:#ff8686;fill-opacity:1;stroke:none;stroke-width:0.05;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="m 42.242382,30.360937 -0.240818,-0.898742 c 0,0 2.365583,-0.721477 4.283982,0.386112 2.149798,1.241186 2.505274,3.282487 2.505274,3.282487 l -0.926042,0.297656 c 0,0 -0.30343,-1.725542 -2.083594,-2.753321 -1.619243,-0.93487 -3.538802,-0.314192 -3.538802,-0.314192 z" id="path911" sodipodi:nodetypes="ccsccsc"></path>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <router-link to="/" class="sidebar-vof-heading">VirtualOffice</router-link>
           
            <div id="close-sidebar">
              <i class="fas fa-times pl-5 pt-2 pb-2"></i>
            </div>
          </div>
          <!-- sidebar-header  -->
          <div class="sidebar-item sidebar-header profile-box">
            <div v-if="profile.profileImage" id="profile-image" :style="{backgroundImage: `url(${profile.profileImage})`}">
                  
            </div>
            <div v-if="profile.profileImage==null" id="no-profile-image">
              <i class="fas fa-user"></i>
            </div>
            <div class="user-info">
                <p @click="changeCurrentRoom('My profile')">
                  <router-link class="profile-name" to="/main/myprofile">
                     <p class="profile-name">{{ profile.name }} <strong>{{ profile.surname }}</strong></p>
                  </router-link>
                </p>
            </div>
          </div>
          <!-- sidebar-menu  -->
          <div class="sidebar-item sidebar-menu text-left">
            <ul>
              <li v-if="profile.role == 'admin'"
                style="font-size: 14px"
                v-bind:class="{ active: roomType.currentType == 'Admin' }"
                @click="changeCurrentRoom('Admin')"
              >
                <router-link to="/main/admin">
                  <i class="fas fa-unlock-alt"></i>
                  <span id="sidebar-item-li">Admin</span>
                </router-link>
              </li>
              <li class="header-menu">
                <span>Main rooms</span>
              </li>
              <li v-if="profile.role != 'viewer'"
                v-bind:class="{
                  active: roomType.currentType == 'Add a new room',
                }"
                @click="changeCurrentRoom('Add a new room')"
              >
                <router-link to="/main/frontpage">
                  <i class="fas fa-plus"></i>
                  <span id="sidebar-item-li" data-toggle="modal" data-target="#addRoomModal"
                    >Add a new room</span
                  >
                </router-link>
              </li>
              <li
                style="font-size: 14px"
                v-bind:class="{ active: roomType.currentType == 'Front Page' }"
                @click="changeCurrentRoom('Front Page')"
              >
                <router-link to="/main/frontpage">
                  <i class="fas fa-home"></i>
                  <span id="sidebar-item-li">Dashboard</span>
                </router-link>
              </li>
              <li
                style="font-size: 14px"
                v-bind:class="{ active: roomType.currentType == 'Project Timeline' }"
                @click="changeCurrentRoom('Project Timeline')"
              >
                <router-link to="/main/projecttimeline">
                  <i class="fas fa-tasks"></i>
                  <span id="sidebar-item-li">Project Timeline</span>
                </router-link>
              </li>
              <li
                v-bind:class="{ active: roomType.currentType == 'News Board' }"
                @click="changeCurrentRoom('News Board')"
              >
                <router-link to="/main/newsboard">
                  <i class="fas fa-newspaper"></i>
                  <span id="sidebar-item-li">Notice Board</span>
                </router-link>
              </li>
              <li
                v-bind:class="{
                  active: roomType.currentType == 'Relaxation Room',
                }"
                @click="changeCurrentRoom('Relaxation Room')"
              >
                <router-link to="/main/relaxation">
                  <i class="fas fa-heart"></i>
                  <span id="sidebar-item-li">Relaxation Room</span>
                </router-link>
              </li>
              <li
                v-bind:class="{ active: roomType.currentType == 'Gallery' }"
                @click="changeCurrentRoom('Gallery')"
              >
                <router-link to="/main/gallery">
                  <i class="fas fa-images"></i>
                  <span id="sidebar-item-li">Gallery</span>
                </router-link>
              </li>
              <li
                v-bind:class="{ active: roomType.currentType == 'Quiz Room' }"
                @click="changeCurrentRoom('Quiz Room')"
              >
                <router-link to="/main/quiz">
                  <i class="fas fa-question-circle"></i>
                  <span id="sidebar-item-li">Quiz Room</span>
                </router-link>
              </li>
              <li
                style="font-size: 14px"
                v-bind:class="{ active: roomType.currentType == 'Direct messages' }"
                @click="changeCurrentRoom('Direct messages')"
              >
                <router-link to="/main/directmessages">
                  <i class="fas fa-comments"></i>
                  <span id="sidebar-item-li">Direct messages</span>
                </router-link>
              </li>
              <li style="font-size: 14px">
                <a href="https://vof-frontend-react.herokuapp.com/" target='_blank'>
                  <i class="fas fa-video"></i>
                  <span id="sidebar-item-li">Join video chat</span>
                </a>
              </li>
              <!---
              <li
                v-bind:class="{ active: roomType.currentType == 'My messages' }"
                @click="changeCurrentRoom('My messages')"
              >
                <router-link to="/main/mymessages">
                  <i class="fas fa-home"></i>
                  <span>My messages</span>
                </router-link>
              </li>--->
              <li style="font-size: 14px">
                <a href="#" @click="logOut()">
                  <i class="fa fa-power-off"></i>
                  <span id="sidebar-item-li">Log out</span>
                </a>
              </li>
            </ul>
          </div>
          
          <div class="sidebar-item sidebar-menu text-left">
            <p class="header-menu">
              <span >Generic rooms</span>
            </p>
            <ul>
              <li
                v-for="(room, index) in rooms.filter(room => room.roomType == 'genericroom')"
                :key="index"
                @click="changeCurrentRoom(room.ime)"
              >
                <router-link to="/main">
                  <span id="sidebar-item-li">{{ room.ime }} </span>
                  <span class="message-counter" v-if="findUsersRoom(room)[0].messagesCounter < room.messagesCounter">{{room.messagesCounter - findUsersRoom(room)[0].messagesCounter}}</span>
                  <i v-if="profile.role != 'viewer'" class="fas fa-times" @click="deleteRoom(room)"></i>
                </router-link>
              </li>
            </ul>
          </div>
          <div class="sidebar-item sidebar-menu text-left">
            <p class="header-menu">
              <span>Direct messages</span>
            </p>
            <ul>
              <li
                v-for="(room, index) in rooms.filter(room => room.roomType == 'directmessages')"
                :key="index"
                @click="changeCurrentRoom(room.ime)"
              >
                <router-link to="/main" v-if="profile.id == room.sender.id || profile.id == room.recipient.id">
                  <span id="sidebar-item-li" v-if="profile.id == room.sender.id">{{ room.recipient.name }} {{ room.recipient.surname }}</span>
                  <span id="sidebar-item-li"  v-if="profile.id == room.recipient.id">{{ room.sender.name }} {{ room.sender.surname }}</span>
                  <span class="message-counter" v-if="findUsersRoom(room)[0].messagesCounter < room.messagesCounter">{{room.messagesCounter - findUsersRoom(room)[0].messagesCounter}}</span>
                  <i class="fas fa-times" @click="deleteRoom(room)"></i>
                </router-link>
              </li>
            </ul>
          </div>
          <!-- sidebar-menu  -->
        </div>
      </nav>
      <!-- sidebar-content  -->
      <main class="page-content">
        <router-view></router-view>

        <!--- Chat --->
        <div id="flex-grid" v-if="customRoom">
            <div
              v-show="tab == 'chat'"
              id="chat"
              style="display: flex; flex-direction: column"
            >
                <div id="options-box">
                  <div id="left-side">
                    <div class="room-name" v-if="this.roomName.length<20">
                      <p>{{this.roomName}}</p>
                    </div>
                    <div v-if="this.roomName.length<20" class="room-description">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                       <div class="room-description-users" v-if="this.roomName.length<20">
                           <p>Public</p>
                         <i @click="showModal" class="fas fa-users"></i>
                          <p id="user-number">{{this.userCounter-1}}</p>
                          <b-modal ref="my-modal" hide-footer title="Current users">
                            <!-- <div class="d-block text-center">
                              <h3>Hello From My Modal!</h3>
                            </div> -->
                            <ul class="user-list list-group">
                                <li class="user-item list-group-item" v-for="(user, index) in users" v-bind:key="index" v-show="profile.id != user.id"> {{ user.name }} {{ user.surname }} </li>
                             </ul>
                          </b-modal>
                       </div>
                    </div>
                  </div> 
                  <div id="right-side">
                      <div id="user-count">
                        <div class="todo-chat-nav">
                          <div class="div chat-box" @click="changeToChat">
                            <i class="fas fa-comment-dots"></i>
                          </div>
                          <div class="div todos-box" @click="changeToTodos">
                            <i class="far fa-clipboard"></i>
                          </div>
                          </div>
                         
                      </div>
                  </div>
              </div> 
              <div id="alert-msg">
                <h4  class="mt-4" v-if="messages.length == 0">
                  There aren't any messages for this room.
                </h4>
              </div>


              <div
                id="flex-div"
                style="
                  display: flex;
                  flex-direction: column-reverse;
                  align-items: left;
                  overflow-y: scroll;
                "
              >
                <div    
                  id="message-box"
                  v-for="(message, index) in messages"
                  v-bind:key="index"
                >
                <div id="user-content-flex">
                    
                    <div class="user-chat-info">
                      <div v-if="message.profileImg" id="user-profile-img" :style="{backgroundImage: `url(${message.profileImg})`}">
                        
                      </div>
                      <div v-if="message.profileImg==null" id="no-profile-image">
                        <i class="fas fa-user"></i>
                      </div>
                      <div id="user-name">
                        <p id="name">{{ message.name }} {{ message.surname }}</p>
                        <p id="time">{{message.timestamp}}</p>
                      </div>
                    </div>

                    <div id="msg-content">
                       <p>{{ message.content }}</p>
                    </div>

                </div>
            
                </div>
              </div>
            </div>



            <div id="input-send-box" v-show="tab == 'chat'">
                <input
                  class="form-control form-control-lg"
                  type="text"
                  placeholder="Type something..."
                  v-model="messageInfo.content"
                  @keyup.enter="sendMessage"
                />
                <!-- <button class="btn btn-success ml-2" @click="sendMessage">
                  Send
                </button> -->
                
                <div id="send-btn-box" @click="sendMessage">
                  <button type="button" class="btn btn-info">Send</button>
                </div>
            </div>


            <div
              v-show="tab == 'chat'"
              style="
                position: fixed;
                bottom: 50px;
                width: 30%;
                display: flex;
                align-items: center;
                margin-left: 15%;
              "
            ></div>

            <!--- Todos --->
            <div
              class="modal fade"
              id="addTodoModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Add a new {{ this.roomType.currentType }} Todo
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Todo Title</label>
                      <input
                        type="text"
                        v-model="todo.title"
                        class="form-control"
                        placeholder="Todo Title"
                      />
                    </div>
                    <div class="form-group">
                      <label for="exampleInputPassword1"
                        >Todo Description</label
                      >
                      <input
                        type="text"
                        v-model="todo.description"
                        class="form-control"
                        placeholder="Todo Description"
                      />
                    </div>
                    <datepicker
                      :disabled-dates="datepickerState.disabledDates"
                      v-model="todo.deadline"
                      placeholder="Deadline"
                    ></datepicker>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    <button class="btn btn-info" @click="addNewTodo">
                      Add Todo
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!--- Todos --->
            <div v-show="tab == 'todos'" class="todos-main-div">

              <div class="todos-siwtch-box">
                <div>
                  <button
                class="btn btn-primary"
                data-toggle="modal"
                data-target="#addTodoModal"
              >
                Add Todo
              </button>
                </div>

                <div class="todo-chat-nav">
                  <div class="div chat-box" @click="changeToChat">
                    <i class="fas fa-comment-dots"></i>
                  </div>
                  <div class="div todos-box" @click="changeToTodos">
                    <i class="far fa-clipboard"></i>
                  </div>
                  </div>
              </div>
              <div v-if="todos.length == 0" id="alert-msg">
                <h2>There aren't any Todos for this room.</h2>
              </div>
              

              <div class="todos-flex-box row row-cols-1 row-cols-sm-2 row-cols-md-4">
  

              <div id="todo-card-main-div" v-for="(todo, index) in todos" v-bind:key="index" class="col" >
                <b-card 
                  id="todo-card" 
                  header-tag="header" 
                  footer-tag="footer">
                  <template>
                    <h3>{{todo.title}}</h3>
                  </template>
                  <b-card-text>
                      {{todo.description}}.
                    <p>Posted: {{ todo.datePosted }}</p>
                    <p style="font-weight: bold">
                      Deadline: {{ todo.deadlineDate }}
                    </p>
                  </b-card-text>
                  <template #footer>
                    <div class="todo-footer-div">
                      <b-button  @click="markAsComplete(todo)" href="#"  v-bind:class="{
                          'btn-warning': !todo.completed,
                          'btn-success': todo.completed,
                        }">{{ todo.completed ? "Yes" : "No" }}</b-button>
                    <b-button @click="deleteTodo(todo)" class="btn btn-danger">
                      Delete Todo
                    </b-button>
                    </div>
                  </template>
                </b-card>
              </div>


             <!-- <div id="todos-flex">
                <div
                  id="todo-box"
                  v-for="(todo, index) in todos"
                  v-bind:key="index"
                >
                  <div id="todo-title">
                    <p class="h5">{{ todo.title }}</p>
                  </div>

                  <div id="todo-description">
                    <p>{{ todo.description }}</p>
                    <p>Posted: {{ todo.datePosted }}</p>
                    <p style="font-weight: bold">
                      Deadline: {{ todo.deadlineDate }}
                    </p>
                    <div id="togle-button-box">
                      <p>Finished:</p>
                      <button
                        @click="markAsComplete(todo)"
                        class="btn"
                        v-bind:class="{
                          'btn-warning': !todo.completed,
                          'btn-success': todo.completed,
                        }"
                      >
                        {{ todo.completed ? "Yes" : "No" }}
                      </button>
                    </div>
                  </div>
                  <div id="delete-box">
                    <button @click="deleteTodo(todo)" class="btn btn-danger">
                      Delete Todo
                    </button>
                  </div>
                </div>
              </div>  -->
            </div>
            </div>
        </div>
      </main>
    </div>
    <!-- page-wrapper -->
  </div>
</template>

<script>
import $ from "jquery";
import { fb, db } from "../../backend/firebase.js";
import Datepicker from "vuejs-datepicker";
const dateFormat = require("dateformat");
//import firebase from "firebase/app";


export default {
  name: "Main",
  components: {
    Datepicker,
  },
  data() {
    return {
      datepickerState: {
        disabledDates: {
          to: new Date(),
        },
      },
      rooms: [],
      roomType: {
        currentType: null,
      },
      profile: {
        name: null,
        surname: null,
        email: null,
        profileImage:null
      },
      tab: "chat",
      profile: {
        name: null,
        surname: null,
        email: null, 
        profileImage: null,
        messagesCounter: null
      },
      messageInfo: {
        id: null,
        name: null,
        surname: null,
        profileImg:null,
        email: null,
        content: "",
        time: null,
        timestamp:null,
        roomType: null,
      },
      todo: {
        title: null,
        description: null,
        deadline: null,
        deadlineDate: null,
        id: null,
        completed: false,
        time: null,
        datePosted: null,
      },
      newRoom: {
        ime: "",
        id: null,
        roomType: null,
        messagesCounter: 0
      },
      todos: [],
      users:[],
      messages: [],
      customRoom: false,
      chatsToDelete: [],
      userCounter:0,
      roomName:0,
      currentRoom: null,
      notificationStatus: null
    };
  },

  created(){
    db.collection("profiles").get().then((querySnapshot) => {
      this.userCounter=0;
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            this.userCounter++;
        });
    });

    //console.log(this.profile.profileImage);
  },

  methods: {
    findUsersRoom: function(currentRoom) {
      return this.profile.inbox.filter(room => {
        return room.roomId == currentRoom.id
      })
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    //Adding new rooms
    addNewRoom() {
      Swal.fire({
        title: "Are you sure you want to add this room?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, I am.",
        cancelButtonText: "No, I am not.",
      }).then((result) => {
        if(this.newRoom.ime == null || this.newRoom.ime == "") {
          Toast.fire({
              icon: "error",
              title: "Please enter all the mandatory fields of the ticket!",
          });
        } else {
          var id = this.generateID(26);
          let chosenExistingRoom = false;
          if (this.newRoom.ime.toLowerCase() == "Relaxation Room".toLowerCase()) {
            chosenExistingRoom = true;
          }
          if (!chosenExistingRoom) {
            this.rooms.forEach((myroom) => {
              if (myroom.ime.toLowerCase() == this.newRoom.ime.toLowerCase()) {
                chosenExistingRoom = true;
              }
            });
          }
          if (chosenExistingRoom) {
            alert("Room already exists!");
          } else {
            this.newRoom.id = id;
            this.newRoom.roomType = "genericroom"
            this.newRoom.messagesCounter = 0
            db.collection("rooms").doc(id).set(this.newRoom);
            this.users.forEach(user => {
              user.inbox = [...user.inbox, {roomId: this.newRoom.id, roomName: this.newRoom.ime, messagesCounter: 0}]
              db.collection("profiles").doc(user.id).set(user);
            })
            Toast.fire({
              icon: "success",
              title: "New room added!",
            });
            this.newRoom.ime = "";
            $("#addRoomModal").modal("hide");
          }
        }
      });
    },

    deleteRoom(room) {
      Swal.fire({
        title: "Are you sure you want to delete this room?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, I am.",
        cancelButtonText: "No, I am not.",
      }).then((result) => {
        if (result.value) {
          //Brisanje sobe iz historije profila svih usera
          this.users.forEach(user => {
            user.inbox = user.inbox.filter(usersRoom => {
              return usersRoom.roomId != room.id
            })
            db.collection("profiles").doc(user.id).set(user);
          })
          this.chatsToDelete.forEach(chatToDelete => {
            if(chatToDelete.roomType == room.ime) {
              db.collection("chats").doc(chatToDelete.id).delete().then(() => {
                  //Successful
              }).catch((error) => {
                  console.error("Error removing document: ", error);
              });
            } 
          })
          db.collection("rooms").doc(room.id).delete();
          Toast.fire({
            icon: "success",
            title: "Room deleted!",
          });
          this.tab = null;
          this.$router.replace("main/frontpage");
        }
        /*
        this.chatsToDelete = this.chatsToDelete.filter(chat => {
          return chat.roomType == room.roomType
        })
        this.chatsToDelete.forEach(chat => {
          db.collection("chats").doc(chat.id).delete();
        })*/
      });
    },

    //Todo methods
    addNewTodo() {
      this.todo.deadlineDate = dateFormat(this.todo.deadline, "fullDate");
      this.todo.time = new Date();
      this.todo.datePosted = dateFormat(this.todo.time, "fullDate");
      this.todo.roomType = this.roomType.currentType;
      var id = this.generateID(26);
      this.todo.id = id;
      db.collection("todos").doc(id).set(this.todo);
      $("#addTodoModal").modal("hide");
      this.resetTodo();
    },
    resetTodo() {
      this.todo = {
        title: null,
        description: null,
        roomType: null,
        deadline: null,
        deadlineDate: null,
        id: null,
        completed: false,
        time: null,
        datePosted: null,
      };
    },
    markAsComplete(todo) {
      Swal.fire({
        title: "Do you want to mark this Todo as complete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, I do.",
        cancelButtonText: "No, I don't.",
      }).then((result) => {
        todo.completed = !todo.completed;
        db.collection("todos").doc(todo.id).update(todo);
        if (result.value) {
          Toast.fire({
            icon: "success",
            title: "Todo marked as complete!",
          });
        }
      });
    },
    deleteTodo(todo) {
      Swal.fire({
        title: "Are you sure you want to delete this Todo?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, I am.",
        cancelButtonText: "No, I am not.",
      }).then((result) => {
        if (result.value) {
          db.collection("todos").doc(todo.id).delete();
          Toast.fire({
            icon: "success",
            title: "Todo deleted!",
          });
        }
      });
    },

    //Chat methods
    sendMessage() {
      this.messageInfo.name = this.profile.name;
      this.messageInfo.surname = this.profile.surname;
      if(this.profile.profileImage==null){
        this.messageInfo.profileImg = null;
      }else{
        this.messageInfo.profileImg = this.profile.profileImage;
      }
      this.messageInfo.email = this.profile.email;
      var date= new Date()+7200;
      var format_date_time=date.substring(4,21);
      this.messageInfo.timestamp=format_date_time;
      //Vrijeme slanja poruke
      this.messageInfo.time = fb.firebase_.firestore.Timestamp.now().toDate()
      
      this.messageInfo.roomType = this.roomType.currentType;
      var id = this.generateID(26);
      this.messageInfo.id = id;
      if(this.messageInfo.content.length!=0){
        db.collection("chats").doc(id).set(this.messageInfo);
        db.collection("rooms").doc(this.currentRoom.id).get().then(doc => {
          if (doc.exists) {
              db.collection("rooms").doc(this.currentRoom.id).update({
                messagesCounter: doc.data().messagesCounter + 1
              })
              //console.log(this.profile)
              this.profile.inbox.forEach(room => {
                if(room.roomId == this.currentRoom.id) { //here
                  room.messagesCounter++;
                }
              })
              db.collection("profiles").doc(this.profile.id).set(this.profile)
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
        })
      }
      this.messageInfo.content = "";
      this.messageInfo.time = null;
    },

    changeCurrentRoom(room) {
      //Postavljanje tipa sobe i njenog imena
      this.roomType.currentType = room;
      this.roomName=room;

      let chosenCustomRoom = false;
      this.rooms.forEach((myroom) => {
        if (myroom.ime == room) {
          chosenCustomRoom = true;
          this.currentRoom = myroom //Postavljanje trenutne sobe (cijeli entity)
        }
      });
      if (chosenCustomRoom) {
        //Citanje poruka u sobi
        var currentUser = fb.auth().currentUser;
        db.collection("profiles").doc(currentUser.uid).get().then(snapshot => {
          this.profile = snapshot.data()
          this.profile.inbox.forEach((usersRoom, index) => {
            if(usersRoom.roomId == this.currentRoom.id) {
              this.profile.inbox[index].messagesCounter = this.currentRoom.messagesCounter
              db.collection("profiles").doc(this.profile.id).update(this.profile)
            }
          })
        })

        this.customRoom = true;
        this.tab = "chat";
        db.collection("roomType")
          .doc("YmtxkUbLK8DEdCz9ejTG")
          .update(this.roomType);
        db.collection("chats")
          .where("roomType", "==", `${this.roomType.currentType}`)
          .orderBy("time", "desc")
          .onSnapshot((querySnapshot) => {
            this.messages = [];
            querySnapshot.forEach((doc) => {
              this.messages = [...this.messages, doc.data()];
            });
          });
        db.collection("todos")
          .where("roomType", "==", `${this.roomType.currentType}`)
          .orderBy("time", "desc")
          .onSnapshot((querySnapshot) => {
            this.todos = [];
            querySnapshot.forEach((doc) => {
              this.todos = [...this.todos, doc.data()];
            });
          });
      } else {
        this.customRoom = false;
      }
    },
    closeMenu() {
      $(".page-wrapper").toggleClass("toggled");
    },
    logOut() {
      fb.auth()
        .signOut()
        .then(() => {
          this.$router.replace("/");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changeToChat() {
      this.tab = "chat";
    },
    changeToTodos() {
      this.tab = "todos";
    },
    generateID(length) {
      var ret = "";
      while (ret.length < length) {
        ret += Math.random().toString(16).substring(2);
      }
      return ret.substring(0, length);
    },
  },
  firestore() {
    const user = fb.auth().currentUser;
    return {
      profile: db.collection("profiles").doc(user.uid),
      users: db.collection("profiles"),
      todos: db.collection("todos"),
      roomType: db.collection("roomType").doc("YmtxkUbLK8DEdCz9ejTG"),
      rooms: db.collection("rooms"),
      chatsToDelete: db.collection("chats"),
    };
  },
};
</script>

<style scoped src="./main.css">
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
</style>