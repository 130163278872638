<template>
  <div>
    <div class="play-list">
        <div v-bind:key="video_3.id.videoId" v-for="video_3 in playlist_videos">
            <PlayListItem v-bind:video_3="video_3"/>
        </div>
    </div>
  </div>
</template>

<script>

import PlayListItem from './PLaylist-item'
export default {
  name: 'PlayList',
  props: ['video_3','playlist_videos'],
  components:{
      PlayListItem
  },
};
</script>

<style scoped>
.card-body{
  background-color: rgba(96, 180, 213, 0.3);
  transition-duration: 0.6s;
  width: 800px;
}
.card-body:hover{
  background-color: rgba(96, 180, 213, 0.7);
  transition-duration: 0.6s;
}

.recomended-list{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
}

.play-list{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
}
@media (max-width: 1200px){
  .card-body{
    width: 600px;
  }
}
@media (max-width: 1000px){
  .card-body{
    width: 500px;
  }
}
@media (max-width: 800px){
  .card-body{
    width: 400px;
  }
}
@media (max-width: 600px){
  .card-body{
    width: 300px;
  }
}
</style>