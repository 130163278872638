<template>
  <div>
    <div class="recomended-box">
        <a
          :href="'https://www.youtube.com/watch?v=' + video_2.id.videoId"
          target="_blank"
          class="recomended-box"
        ><img :src="video_2.snippet.thumbnails.high.url" alt="slika" id="video_img"></a>
        <p id="title">{{video_2.snippet.title}}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'RecomenndedBox',
  props: ['video_2'],

  data(){
      return{
          videos: [],
          reformattedSearchString: '',
          api: {
            baseUrl: 'https://www.googleapis.com/youtube/v3/search?',
            part: 'snippet',
            type: 'video',
            order: 'viewCount',
            maxResults: 12,
            q: 'happy mood',
            key: 'AIzaSyC-saRAnT-EXmWtn0VWjtiNr2e1kH2r_Tw',
            prevPageToken: '',
            nextPageToken: ''
        }
    }
  },

  /*created(){
      const { baseUrl, part, type, order, maxResults, q, key } = this.api;
      const user_list_api=`${baseUrl}part=${part}&type=${type}&order=${order}&q=${q}&maxResults=${maxResults}&key=${key}`
       axios
        .get(user_list_api)
        .then(res => {
          this.videos = res.data.items;
          console.log(this.videos);
        })
        .catch(error => console.log(error));
  },*/

  methods: {
      search(searchParams) {
      this.reformattedSearchString = searchParams.join(' ');
      this.api.q = searchParams.join('+');
      const { baseUrl, part, type, order, maxResults, q, key } = this.api;
      const apiUrl = `${baseUrl}part=${part}&type=${type}&order=${order}&q=${q}&maxResults=${maxResults}&key=${key}`;
      this.getData(apiUrl);
    },

    prevPage() {
      const { baseUrl, part, type, order, maxResults, q, key, prevPageToken } = this.api;
      const apiUrl = `${baseUrl}part=${part}&type=${type}&order=${order}&q=${q}&maxResults=${maxResults}&key=${key}&pageToken=${prevPageToken}`;
      this.getData(apiUrl);
    },

    nextPage() {
      const { baseUrl, part, type, order, maxResults, q, key, nextPageToken } = this.api;
      const apiUrl = `${baseUrl}part=${part}&type=${type}&order=${order}&q=${q}&maxResults=${maxResults}&key=${key}&pageToken=${nextPageToken}`;
      this.getData(apiUrl);
    },

    getData(apiUrl) {
      axios
        .get(apiUrl)
        .then(res => {
          this.videos = res.data.items;
          this.api.prevPageToken = res.data.prevPageToken;
          this.api.nextPageToken = res.data.nextPageToken;
          console.log(this.videos);
        })
        .catch(error => console.log(error));
    }
  }


};
</script>

<style scoped>
.card-body{
  background-color: rgba(96, 180, 213, 0.3);
  transition-duration: 0.6s;
  width: 800px;
}
.card-body:hover{
  background-color: rgba(96, 180, 213, 0.7);
  transition-duration: 0.6s;
}

.recomended-box{
    display: flex;
    flex-direction: column;
    width: 300px;
    min-width: 200px;
    max-width: 300px;
    min-height: 240px;
    height: 350px;
    max-height: 300px;
    border-radius: 20px;
    font-size: 15px;
    font-family: 'Montserrat';
    color: black;
    font-weight: 700;
    cursor: pointer;
    margin-right: 1%;
    transition: transform .2s;
}

#title{
  color: rgb(87, 87, 87);
}

#video_img{
  border-radius: 20px;
}
@media (max-width: 1200px){
  .card-body{
    width: 600px;
  }

}
@media (max-width: 1000px){
  .card-body{
    width: 500px;
  }

}
@media (max-width: 800px){
  .card-body{
    width: 400px;
  }

  .recomended-box{
    margin-top: 30px;
}
}
@media (max-width: 600px){
  .card-body{
    width: 300px;
  }

}
</style>