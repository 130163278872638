<template>
    <div id="EditTicket">
        <div
            class="modal fade"
            id="editTicketModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            Edit ticket
                        </h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="list-group">
                            <b>Deadline:</b> {{ ticket.deadlineDate }}
                        </div>
                        <br>
                        <div class="form-group">
                            <label for="exampleInputPassword1">Ticket Title</label>
                            <input
                            type="text"
                            v-model="ticket.title"
                            class="form-control"
                            placeholder="Ticket Title"
                            :disabled="profile.role == 'viewer'"
                            />
                        </div>
                        <div class="form-group">
                            <label for="exampleInputPassword1"
                            >Ticket Description</label
                            >
                            <input
                            type="text"
                            v-model="ticket.description"
                            class="form-control"
                            placeholder="Ticket Description"
                            :disabled="profile.role == 'viewer'"
                            />
                        </div>
                        <hr />
                        <p class="text-bold">Ticket's status</p>
                        <hr />
                        <div class="list-group">
                            <a href="#" class="list-group-item list-group-item-action" v-for="(statusOption, index) in statusOptions" :key="index" @click="assignStatus(statusOption)" v-bind:class="{ 'active': ticket.status == statusOption, 'disabled-field': profile.role == 'viewer' }">
                                {{ statusOption }}
                            </a>
                        </div>
                        <hr />
                        <p class="text-bold">Ticket's priority</p>
                        <hr />
                        <div class="list-group">
                            <a href="#" class="list-group-item list-group-item-action" v-for="(priorityOption, index) in priorityOptions" :key="index" @click="assignPriority(priorityOption)" v-bind:class="{ 'active': ticket.priority == priorityOption, 'disabled-field': profile.role == 'viewer' }">
                                {{ priorityOption }}
                            </a>
                        </div>
                        <hr />
                        <p class="text-bold">Assigned users:</p>
                        <ul class="list-group">
                            <li
                            v-for="user in ticket.assignedTo"
                            v-bind:key="user.id"
                            class="list-group-item"
                            >
                            {{ user.email
                            }}<i v-if="profile.role != 'viewer'"
                                @click="unassignUser(user)"
                                class="fas fa-times float-right"
                                style="font-size: 24px"
                            ></i>
                            </li>
                        </ul>
                        <hr />
                        <p v-if="profile.role != 'viewer'" class="text-bold">Assign the ticket to a specific person:</p>
                        <select v-if="profile.role != 'viewer'"
                            class="form-control w-100"
                            id="exampleFormControlSelect1"
                            v-model="currentAssignee"
                        >
                            <option
                            v-for="user in profiles"
                            v-bind:key="user.id"
                            v-bind:value="user"
                            
                            >
                            {{ user.email }}
                            </option>
                        </select>
                        <button
                            class="btn btn-primary mt-2 mb-2"
                            @click="assignUser"
                            v-if="currentAssignee != null"
                        >
                            Assign user
                        </button>
                        <hr />
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                        <button
                            v-if="profile.role != 'viewer'"
                            type="button"
                            class="btn btn-danger"
                            data-dismiss="modal"
                            @click="deleteTicket"
                        >
                            Delete Ticket
                        </button>
                        <button class="btn btn-info" @click="updateTicket" v-if="profile.role != 'viewer'">
                            Update Ticket
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { fb, db } from "../../backend/firebase.js";

export default {
    name: "EditTicket",
    props: {
        ticket: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            statusOptions: ["To do", "On hold", "In progress", "Pending review", "QA pass", "Done"],
            priorityOptions: ["Low", "Medium", "High"],
            currentAssignee: null,
            profiles: [],
            originalProfiles: []
        }
    },
    methods: {
        assignUser() {
            let profileFound = false;
            this.ticket.assignedTo.forEach(assignee => {
                if(assignee.email == this.currentAssignee.email) {
                    profileFound = true;
                    this.profiles = this.profiles.filter((profile) => {
                        return profile.email != this.currentAssignee.email;
                    });
                    this.currentAssignee = null;
                }
            })
            if(!profileFound) {
                this.ticket.assignedTo = [...this.ticket.assignedTo, this.currentAssignee];
                this.profiles = this.profiles.filter((profile) => {
                    return profile.email != this.currentAssignee.email;
                });
                this.currentAssignee = null;
            }
        },
        unassignUser(user) {
            this.ticket.assignedTo = this.ticket.assignedTo.filter((myUser) => {
                return myUser.email != user.email;
            });
            this.profiles = [...this.profiles, user];
        },
        assignStatus(status) {
            this.ticket.status = status;
        },
        assignPriority(priority) {
            this.ticket.priority = priority;
        },
        updateTicket() {
            this.profiles = this.originalProfiles
            db.collection("tickets").doc(this.ticket.id).update(this.ticket);
            Toast.fire({
                icon: "success",
                title: "Ticket successfully updated!",
            });
            $("#editTicketModal").modal("hide");
        },
        deleteTicket() {
            Swal.fire({
                title: "Are you sure you want to delete this ticket?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#28a745",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, I am.",
                cancelButtonText: "No, I am not.",
            }).then((result) => {
                if (result.value) {
                    db.collection("tickets").doc(this.ticket.id).delete();
                    Toast.fire({
                        icon: "success",
                        title: "Ticket deleted!",
                    });
                }
            });
        },
    },
    firestore() {
        const user = fb.auth().currentUser;
        return {
            profile: db.collection("profiles").doc(user.uid),
            profiles: db.collection("profiles"),
            originalProfiles: db.collection("profiles")
        }
    }
}
</script>

<style scoped src="./project-timeline.css">
</style>


