<template>
  <div class="items-box">
      <div class="card mb-2" v-bind:key="video.id.videoId" v-for="video in videos">
        <VideoListItem v-bind:video="video"/>
      </div>
  </div>
</template>

<script>
import VideoListItem from './VideoListItem';

export default {
  name: 'SearchResults',
  components: {
    VideoListItem,
  },
  data() {
    return {
      title: 'Search Results',
      displayMode: 'list'
    };
  },
  methods: {
    changeDisplayMode(displayMode) {
      this.displayMode = displayMode;
    }
  },
  props: ['videos', 'reformattedSearchString']
};
</script>

<style scoped src="./search-results.css">
</style>