<template>
  <div>
    <div class="result-box">
        <div class="img-box">
          <img :src="video.snippet.thumbnails.high.url" alt="slika" id="video_img">
        </div>
        <a id="link" :href="'https://www.youtube.com/watch?v=' + video.id.videoId" target="_blank">
          <div class="description-box">
          <div class="video-title">
            <p>{{video.snippet.title.substring(0,30)}}...</p>
          </div>
          <div class="video-description">
            <p>{{video.snippet.description.substring(0,50)}}</p>
          </div>
        </div>
        </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoListItem',
  props: ['video']
};
</script>

<style scoped>
.result-box{
  transition-duration: 0.6s;
  width: 70%;
  height: 50px;
  display: flex;
}

#link{
  width: 100%;
  text-decoration: none;
}
.img-box{
  width: 15%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.img-box img{
  width: 100%;
  height: 100%;
}
.description-box{
  width: 140%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}
.video-title{
  height: 50%;
  text-align: left;
  color: rgb(31, 31, 31);
}
.video-description{
  height: 50%;
  text-align: left;
  font-size: 12px;
  color: rgb(82, 82, 82);
  width: 100%;
}
.card-body:hover{
  background-color: rgba(96, 180, 213, 0.7);
  transition-duration: 0.6s;
}

@media only screen and (max-width: 700px) {
.result-box{
  transition-duration: 0.6s;
  width: 100%;
  height: 80px;
  display: flex;
}

.img-box{
  width: 35%;
}

.description-box{
  width: 100%;
}

}

</style>