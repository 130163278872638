<template>
  <div>
    <div class="playlist-box">
       <div class="box">
         <div class="box-top">
           <b-form-rating v-model="value" variant="warning" class="mb-2 rating"></b-form-rating>
         </div>
         <div class="box-bottom">
           <div>
             <a
              :href="'https://www.youtube.com/watch?v=' + video_3.id.videoId"
              target="_blank"
            ><p id="title">{{video_3.snippet.title.substring(0,30)}}...</p></a>
             
           </div>
           <div class="description-box">
             <p id="description">{{video_3.snippet.description.substring(0,30)}}...</p>
           </div>
         </div>
       </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'PlayListItem',
  props: ['video_3'],

  data(){
      return{
          videos: [],
          reformattedSearchString: '',
          api: {
            baseUrl: 'https://www.googleapis.com/youtube/v3/search?',
            part: 'snippet',
            type: 'video',
            order: 'viewCount',
            maxResults: 12,
            q: 'happy mood',
            key: 'AIzaSyC-saRAnT-EXmWtn0VWjtiNr2e1kH2r_Tw',
            prevPageToken: '',
            nextPageToken: ''
        },

        value: 4
    }
  },

  /*created(){
      const { baseUrl, part, type, order, maxResults, q, key } = this.api;
      const user_list_api=`${baseUrl}part=${part}&type=${type}&order=${order}&q=${q}&maxResults=${maxResults}&key=${key}`
       axios
        .get(user_list_api)
        .then(res => {
          this.videos = res.data.items;
          console.log(this.videos);
        })
        .catch(error => console.log(error));
  },*/

  methods: {
      search(searchParams) {
      this.reformattedSearchString = searchParams.join(' ');
      this.api.q = searchParams.join('+');
      const { baseUrl, part, type, order, maxResults, q, key } = this.api;
      const apiUrl = `${baseUrl}part=${part}&type=${type}&order=${order}&q=${q}&maxResults=${maxResults}&key=${key}`;
      this.getData(apiUrl);
    },

    prevPage() {
      const { baseUrl, part, type, order, maxResults, q, key, prevPageToken } = this.api;
      const apiUrl = `${baseUrl}part=${part}&type=${type}&order=${order}&q=${q}&maxResults=${maxResults}&key=${key}&pageToken=${prevPageToken}`;
      this.getData(apiUrl);
    },

    nextPage() {
      const { baseUrl, part, type, order, maxResults, q, key, nextPageToken } = this.api;
      const apiUrl = `${baseUrl}part=${part}&type=${type}&order=${order}&q=${q}&maxResults=${maxResults}&key=${key}&pageToken=${nextPageToken}`;
      this.getData(apiUrl);
    },

    getData(apiUrl) {
      axios
        .get(apiUrl)
        .then(res => {
          this.videos = res.data.items;
          this.api.prevPageToken = res.data.prevPageToken;
          this.api.nextPageToken = res.data.nextPageToken;
          console.log(this.videos);
        })
        .catch(error => console.log(error));
    }
  }


};
</script>

<style scoped>
.card-body{
  background-color: rgba(96, 180, 213, 0.3);
  transition-duration: 0.6s;
  width: 800px;
}
.card-body:hover{
  background-color: rgba(96, 180, 213, 0.7);
  transition-duration: 0.6s;
}

.recomended-box{
    display: flex;
    flex-direction: column;
    width: 300px;
    min-width: 200px;
    max-width: 300px;
    min-height: 200px;
    height: 280px;
    max-height: 300px;
    border-radius: 20px;
    font-size: 15px;
    font-family: 'Montserrat';
    color: black;
    font-weight: 700;
    cursor: pointer;
    margin-right: 1%;
    transition: transform .2s;
}

.playlist-box{
    display: flex;
    flex-direction: column;
    width: 300px;
    min-width: 100px;
    max-width: 280px;
    min-height: 150px;
    height: 150px;
    max-height: 300px;
    font-size: 15px;
    font-family: 'Montserrat';
    color: black;
    font-weight: 700;
    cursor: pointer;
    margin-right: 1%;
    transition: transform .2s;
    margin-bottom: 15px;
}

.box{
  width: 100%;
  height: 100%;
  background-color: cadetblue;
  border-radius: 10px;
  background-color: rgb(245, 245, 245);
  padding-bottom: 15px;
}


#title{
  color: rgb(75, 75, 75);
  text-align: left;
  font-size: 15px;
  padding-left: 10px;
}

.box-top{
  height: 30%;
  padding: 10px;
}

.rating{
  border:none;
  background-color: rgb(250, 250, 250);
}

.box-bottom{
  padding-top: 20px;
  height: 70%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

#description{
  font-size: 12px;
  text-align: left;
  color: rgb(78, 78, 78);
  padding-left: 10px;
}


#video_img{
  border-radius: 20px;
}
@media (max-width: 1200px){
  .card-body{
    width: 600px;
  }
}
@media (max-width: 1000px){
  .card-body{
    width: 500px;
  }
}
@media (max-width: 800px){
  .card-body{
    width: 400px;
  }
}
@media (max-width: 600px){
  .card-body{
    width: 300px;
  }
}
</style>