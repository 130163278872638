<template>
  <div id="Home">
    <LoginRegister />
    <!--<Register />-->
    <div id="main-div">
      <div id="logo-img-box">
        <svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" width="38.371395mm" height="26.93634mm" viewBox="0 0 38.371395 26.93634" version="1.1" id="svg8" inkscape:version="1.0.2-2 (e86c870879, 2021-01-15)" sodipodi:docname="Vofina Logo.svg">
          <defs id="defs2"></defs>
          <sodipodi:namedview id="base" pagecolor="#ffffff" bordercolor="#666666" borderopacity="1.0" inkscape:pageopacity="0.0" inkscape:pageshadow="2" inkscape:zoom="3.8963964" inkscape:cx="45.339226" inkscape:cy="74.384798" inkscape:document-units="mm" inkscape:current-layer="layer1" inkscape:document-rotation="0" showgrid="false" inkscape:snap-nodes="true" inkscape:window-width="1920" inkscape:window-height="1017" inkscape:window-x="-8" inkscape:window-y="-8" inkscape:window-maximized="1"></sodipodi:namedview>
          <metadata id="metadata5">
            <rdf:rdf>
              <cc:work rdf:about="">
                <dc:format>image/svg+xml</dc:format>
                <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage"></dc:type>
                <dc:title></dc:title>
              </cc:work>
            </rdf:rdf>
          </metadata>
          <g inkscape:label="Layer 1" inkscape:groupmode="layer" id="layer1" transform="translate(-30.528926,-35.838464)">
            <g id="g914">
              <path style="fill:#1b1b1b;fill-opacity:1;stroke:none;stroke-width:0.1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="m 30.528926,43.097789 h 4.187968 l 5.418928,15.641646 0.986039,-2.752334 c 0.959044,0.959044 1.735555,2.056485 2.588385,3.533631 l -1.199357,3.248603 h -4.767284 z" id="path847" sodipodi:nodetypes="cccccccc"></path>
              <path style="fill:#1b1b1b;fill-opacity:1;stroke:none;stroke-width:0.05;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="m 62.188516,62.766304 3.952248,0.0085 V 50.242283 h 2.759559 v -3.180509 h -2.736172 c 0,-2.141974 -0.03013,-3.028497 2.549083,-3.028497 V 40.70076 c -4.399907,0 -6.501331,1.330584 -6.501331,6.361014 h -1.777344 v 3.180509 h 1.753957 z" id="path919" sodipodi:nodetypes="ccccccccccccc"></path>
              <path id="path849" style="fill:#60b4d5;fill-opacity:1;fill-rule:evenodd;stroke:none;stroke-width:0.0968336;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="M 60.534559,51.333508 A 10.235781,10.478297 0 0 1 50.298779,61.811805 10.235781,10.478297 0 0 1 40.062998,51.333508 10.235781,10.478297 0 0 1 50.298779,40.85521 10.235781,10.478297 0 0 1 60.534559,51.333508 Z"></path>
              <g id="g892" transform="translate(-47.382745,-0.56788205)" style="stroke:none;stroke-opacity:1">
                <path style="fill:#e0e0d1;fill-opacity:1;stroke:none;stroke-width:0.07;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="m 96.070223,54.918093 h 3.236782 c -0.118836,0.87685 -0.0136,1.341912 0.527316,1.946357 l -4.351432,0.0086 c 0.583621,-0.566427 0.70286,-1.10444 0.587334,-1.954931 z" id="path851" sodipodi:nodetypes="ccccc"></path>
                <path style="opacity:0.3;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.05;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="m 94.464003,57.433609 h 1.012681 l 0.08852,-0.09523 4.204827,0.0043 0.0736,0.08185 h 1.084949 c 0.30372,0 0.60853,0.27649 0.60853,0.660859 0,0.43119 -0.29355,0.68814 -0.64617,0.68814 h -6.451176 c -0.306762,0 -0.632874,-0.257512 -0.632874,-0.654796 0,-0.349554 0.300866,-0.685123 0.657128,-0.685123 z" id="path853-9" sodipodi:nodetypes="sccccssssss"></path>
                <path style="fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.05;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="m 94.468577,56.864059 h 1.012681 l 0.08852,-0.09523 4.204822,0.0043 0.07358,0.08185 h 1.08495 c 0.30372,0 0.60853,0.27649 0.60853,0.660859 0,0.43119 -0.29355,0.68814 -0.64617,0.68814 h -6.451172 c -0.306762,0 -0.632874,-0.257512 -0.632874,-0.654796 0,-0.349554 0.300866,-0.685123 0.657128,-0.685123 z" id="path853" sodipodi:nodetypes="sccccssssss"></path>
                <path style="opacity:0.3;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.0400601;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="m 91.322656,53.664876 12.717734,0.01651 v 0.649939 c 0,0.504862 -0.42109,1.188629 -1.10666,1.188629 H 92.519757 c -0.757182,0 -1.192668,-0.647351 -1.192668,-1.238212 z" id="path863" sodipodi:nodetypes="ccccccc"></path>
                <path style="fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.05;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="m 92.447294,45.029254 h 10.520736 c 0.53836,0 1.07196,0.584298 1.07196,1.095996 v 7.548358 c 0,0.756483 -0.56968,1.253061 -1.30841,1.253061 H 92.67297 c -0.711116,0 -1.348597,-0.521778 -1.348597,-1.31534 v -7.481845 c 0.01018,-0.55631 0.562747,-1.061061 1.122921,-1.10023 z" id="path855" sodipodi:nodetypes="ccccccccc"></path>
                <path style="fill:#4f5d73;fill-opacity:1;stroke:none;stroke-width:0.05;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="m 92.301839,46.293272 c 0,-0.158021 0.06831,-0.249288 0.225791,-0.249288 l 3.756823,0.0084 4.394437,7.793905 h -8.160355 c -0.131304,0 -0.195001,-0.06505 -0.195001,-0.195529 z" id="path857" sodipodi:nodetypes="ccccccc"></path>
                <path style="fill:#727d8f;fill-opacity:1;stroke:none;stroke-width:0.05;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="m 96.095946,46.052316 h 6.597894 c 0.21925,0 0.33122,0.118678 0.33225,0.3494 v 7.138066 c 0,0.207906 -0.0818,0.307602 -0.30118,0.307602 l -2.28824,-2e-6 z" id="path859" sodipodi:nodetypes="ccccccc"></path>
              </g>
              <g id="g917" style="fill:#ff8686;fill-opacity:1" transform="translate(12.655784,6.6071898)">
                <circle style="opacity:1;fill:#ff8686;fill-opacity:1;fill-rule:evenodd;stroke:none;stroke-width:0.0377143;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" id="path905" cx="43.255486" cy="34.713722" r="0.48233795"></circle>
                <path style="fill:#ff8686;fill-opacity:1;stroke:none;stroke-width:0.05;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="m 42.901769,33.617495 c 0,0 0.550453,-0.169904 0.976371,0.076 0.415664,0.239984 0.549571,0.754203 0.549571,0.754203 l 0.906211,-0.274788 c 0,0 -0.191032,-0.843851 -0.947137,-1.280388 -0.641159,-0.370173 -1.648716,-0.122777 -1.648716,-0.122777 z" id="path907" sodipodi:nodetypes="csccscc"></path>
                <path style="opacity:1;fill:#ff8686;fill-opacity:1;stroke:none;stroke-width:0.05;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="m 42.358137,31.129882 0.227307,0.848323 c 0,0 1.246353,-0.366839 2.302771,0.243084 0.974455,0.562601 1.248503,1.736328 1.248503,1.736328 l 0.961565,-0.257651 c 0,0 -0.37131,-1.509939 -1.763583,-2.313768 -1.517213,-0.875964 -2.976563,-0.256316 -2.976563,-0.256316 z" id="path909" sodipodi:nodetypes="ccsccsc"></path>
                <path style="fill:#ff8686;fill-opacity:1;stroke:none;stroke-width:0.05;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="m 42.242382,30.360937 -0.240818,-0.898742 c 0,0 2.365583,-0.721477 4.283982,0.386112 2.149798,1.241186 2.505274,3.282487 2.505274,3.282487 l -0.926042,0.297656 c 0,0 -0.30343,-1.725542 -2.083594,-2.753321 -1.619243,-0.93487 -3.538802,-0.314192 -3.538802,-0.314192 z" id="path911" sodipodi:nodetypes="ccsccsc"></path>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div id="description-box">
        <h4 style="padding-bottom: 20px; padding-top:20px;">
          Welcome to <strong>VirtualOffice</strong>
        </h4>
        <p>
          Sign in or register to proceed
        </p>
        <button
          id="nastavi-btn"
          class="btn btn-primary my-2 my-sm-0 text-left"
          type="button"
          data-toggle="modal"
          data-target="#login"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style scoped src="./home.css">
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
</style>
