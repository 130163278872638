<template>
  <div id="RelaxationRoom">
    <div v-if="!selman" class="relaxation-start-page">
      <h4 id="start-page-title">Welcome to VOF Relaxation room.</h4>

      <b-button id="get-started-btn" v-b-modal.modal-prevent-closing>Get started</b-button>
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="How do you feel today?"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Type the keyword of music you want to listen..."
          label-for="name-input"
          invalid-feedback="The field is required"
          :state="nameState"
        >
          <b-form-input
            id="name-input"
            v-model="name"
            :state="nameState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
    </div>
    <div id="info-box" v-if="selman">
      <div class="recomended_description">
        <h3>Recomended Music for you</h3>
      </div>
      <RecomendedList
        v-if="recomended_videos.length > 0"
        v-bind:recomended_videos="recomended_videos"
      />
     <div class="middle-box">
      <div class="left-side">
          <h4 id="search-text">Type the name of the music you want to play</h4>
         <SearchForm v-on:search="search"/>
        <SearchResults
          v-if="videos.length > 0"
          v-bind:videos="videos"
          v-bind:reformattedSearchString="reformattedSearchString"
        />
      </div>

      <div class="right-side"  v-if="selman">
        <h3 id="playlists-title">Playlists</h3>
        <PlayList
        v-if="playlist_videos.length > 0"
        v-bind:playlist_videos="playlist_videos"
      />
     </div>
    </div>
    <!--<Pagination
          v-if="videos.length > 0"
          v-bind:prevPageToken="api.prevPageToken"
          v-bind:nextPageToken="api.nextPageToken"
          v-on:prev-page="prevPage"
          v-on:next-page="nextPage"
        />-->
      </div>
  </div>
</template>

<script>
import { fb, db } from "../../backend/firebase.js";
import Datepicker from "vuejs-datepicker";
const dateFormat = require("dateformat");
import SearchForm from '../../components/RelaxationRoom/SearchForm';
import SearchResults from '../../components/RelaxationRoom/SearchResults';
import Pagination from '../../components/RelaxationRoom/Pagination';
import RecomendedList from '../../components/RelaxationRoom/RecomendedList'
import PlayList from '../../components/RelaxationRoom/Playlist'
import axios from 'axios';

export default {
  name: "RelaxationRoom",
  components: {
    Datepicker,
    SearchForm,
    SearchResults,
    Pagination,
    RecomendedList,
    PlayList
  },
  data() {
    return {
      selman: null,
      name: '',
      nameState: null,
      submittedNames: [],
      profile: {
        name: null,
        surname: null,
        email: null,
      },
      videos: [],
      recomended_videos: [],
      playlist_videos:[],
      reformattedSearchString: '',
      api: {
        baseUrl: 'https://www.googleapis.com/youtube/v3/search?',
        part: 'snippet',
        type: 'video',
        order: 'viewCount',
        maxResults: 12,
        q: '',
        key: 'AIzaSyB2p-nHiPnk-XYurt3XXF8DlCqtqCWu4VI',
        prevPageToken: '',
        nextPageToken: ''
      },

      api2: {
        baseUrl: 'https://www.googleapis.com/youtube/v3/search?',
        part: 'snippet',
        type: 'video',
        order: 'viewCount',
        maxResults: 4,
        key: 'AIzaSyB2p-nHiPnk-XYurt3XXF8DlCqtqCWu4VI',
        prevPageToken: '',
        nextPageToken: ''
      },

      api3: {
        baseUrl: 'https://www.googleapis.com/youtube/v3/search?',
        part: 'snippet',
        type: 'video',
        order: 'viewCount',
        q:'work and study music',
        maxResults: 6,
        key: 'AIzaSyB2p-nHiPnk-XYurt3XXF8DlCqtqCWu4VI',
        prevPageToken: '',
        nextPageToken: ''
      },

    };
  },

  created(){
    let { baseUrl, part, type, order, maxResults, q, key } = this.api3;
      let play_list_api=`${baseUrl}part=${part}&type=${type}&order=${order}&q=${q}&maxResults=${maxResults}&key=${key}`
       axios
        .get(play_list_api)
        .then(res => {
          this.playlist_videos = res.data.items;
          //console.log(this.playlist_videos);
        })
        .catch(error2 => console.log(error2));
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      this.selman=false;
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.selman=true;
      const { baseUrl, part, type, order, maxResults, q, key } = this.api2;
      const user_list_api=`${baseUrl}part=${part}&type=${type}&order=${order}&q=${this.name}&maxResults=${maxResults}&key=${key}`
       axios
        .get(user_list_api)
        .then(res => {
          this.recomended_videos = res.data.items;
          //console.log(this.recomended_videos);
        })
        .catch(error => console.log(error));


      this.handleSubmit();

    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name)
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing')
      })
    },
    generateID(length) {
      var ret = "";
      while (ret.length < length) {
        ret += Math.random().toString(16).substring(2);
      }
      return ret.substring(0, length);
    },
    search(searchParams) {
      this.reformattedSearchString = searchParams.join(' ');
      this.api.q = searchParams.join('+');
      const { baseUrl, part, type, order, maxResults, q, key } = this.api;
      const apiUrl = `${baseUrl}part=${part}&type=${type}&order=${order}&q=${q}&maxResults=${maxResults}&key=${key}`;
      this.getData(apiUrl);
    },

    prevPage() {
      const { baseUrl, part, type, order, maxResults, q, key, prevPageToken } = this.api;
      const apiUrl = `${baseUrl}part=${part}&type=${type}&order=${order}&q=${q}&maxResults=${maxResults}&key=${key}&pageToken=${prevPageToken}`;
      this.getData(apiUrl);
    },

    nextPage() {
      const { baseUrl, part, type, order, maxResults, q, key, nextPageToken } = this.api;
      const apiUrl = `${baseUrl}part=${part}&type=${type}&order=${order}&q=${q}&maxResults=${maxResults}&key=${key}&pageToken=${nextPageToken}`;
      this.getData(apiUrl);
    },

    getData(apiUrl) {
      axios
        .get(apiUrl)
        .then(res => {
          this.videos = res.data.items;
          this.api.prevPageToken = res.data.prevPageToken;
          this.api.nextPageToken = res.data.nextPageToken;
          //console.log(this.videos);
        })
        .catch(error => console.log(error));
    }

  },
  firestore() {
    const user = fb.auth().currentUser;
    return {
      profile: db.collection("profiles").doc(user.uid)
    };
  },
};
</script>

<style scoped src="./relaxation-room.css">



</style>