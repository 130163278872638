<template>
  <div id="AddNews">
    <div class="modal fade" id="addTodoModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">New post</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="exampleInputPassword1">Post Title</label>
              <input
                type="text"
                v-model="post.title"
                class="form-control"
                placeholder="Post title here"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">Post Description</label>
              <input
                type="text"
                v-model="post.description"
                class="form-control"
                placeholder="Post description here"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button class="btn btn-info" @click="addPost">Publish</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fb, db } from "../../backend/firebase";
const axios = require("axios")

export default {
  name: "AddNews",
  data() {
    return {
      post: {
        title: null,
        description: null,
        postedBy: null
      },
      profile: {
        name: null,
        surname: null,
      },
    };
  },
  methods: {
    resetData() {
      this.post = {
        title: null,
        description: null,
        postedBy: null
      };
    },
    addPost() {
      Swal.fire({
          title: "Are you sure you want to add this post?",
          text: "The post will be visible to everyone on VirtualOffice.",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, I am.",
          cancelButtonText: "No, I am not.",
      }).then((result) => {
          if (result.value) {
            this.post.postedBy = this.profile.id;
            axios.post('https://vof-cloud-backend.herokuapp.com/posts', this.post)
            .then((response) => {
              $("#addTodoModal").modal("hide");
              Toast.fire({
                icon: "success",
                title: "New post added successfully!",
              });
              this.resetData();
            }, (error) => {
              Toast.fire({
                icon: "error",
                title: "New post could not be added!",
              });
              console.log(error);
            });
          }
      });
    },
  },
  firestore() {
    const user = fb.auth().currentUser;
    return {
      profile: db.collection("profiles").doc(user.uid),
    };
  },
};
</script>