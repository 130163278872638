<template>
  <div class="input-box">
    <form class="mb-5">
      <div class="input-group">
        <input
          v-model="searchString"
          @keydown.13.prevent="parseSearchString"
          type="text"
          class="form-control"
          placeholder="Search ..."
        >
        <div class="input-group-append">
          <button @click="parseSearchString" class="btn btn-outline-secondary" type="button">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'SearchForm',
  data() {
    return {
      searchString: ''
    };
  },
  methods: {
    parseSearchString() {
      const trimmedSearchString = this.searchString.trim();
      if (trimmedSearchString !== '') {
        const searchParams = trimmedSearchString.split(/\s+/);
        this.$emit('search', searchParams);
        this.searchString = '';
      }
    }
  }
};
</script>

<style scoped>
input,
button {
  box-shadow: none !important;
  
}
button:hover{
  background-color:rgba(96, 180, 213, 1);
}
.container1{
    display: flex;
    justify-content: flex-start;
    padding-left: 60px;
}

.input-box{
  width: 50%;
  margin: 0 auto;
}

@media only screen and (max-width: 700px) {
  .input-box{
  width: 100%;
  margin: 0 auto;
}
}

</style>