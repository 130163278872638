<template>
    <div id="Dashboard">
        <div class="front-header">
            <h3>Hello, <strong>{{profile.name}} {{profile.surname}}</strong></h3>
            <p id="front-header-text">Welcome to the dashboard! Read about everything that is relevant to your company.</p>
        </div>
        <div class="postsWrapper">
            <div class="postWrapper">
                <div class="img-box img_1">
                    <i class="fas fa-tasks"></i>
                </div>
                <div class="description-box">
                    <h3 class="description-title">Project Timeline</h3>
                    <p class="description-text">Keep track of all the tasks within your company's projects, assign them to your colleagues, add deadlines, priorities and more.</p>
                </div>
            </div>
            <div class="postWrapper">
                <div class="img-box img_2">
                    <i class="fas fa-thumbtack"></i>
                </div>
                <div class="description-box">
                    <h3 class="description-title">Notice Board</h3>
                    <p class="description-text">Read all the news/posts of your colleagues, add your own posts and keep on top of every relavant info.</p>
                </div>
            </div>
            <div class="postWrapper">
                <div class="img-box img_3">
                        <i class="fas fa-headset"></i>
                    </div>
                    <div class="description-box">
                        <h3 class="description-title">Relaxation Room</h3>
                        <p class="description-text">Relax after a hard day of work with different types of music, chats with your colleagues and more.</p>
                    </div>
            </div>
            <div class="postWrapper">
                <div class="img-box img_4">
                        <i class="far fa-images"></i>
                    </div>
                    <div class="description-box">
                        <h3 class="description-title">Gallery</h3>
                        <p class="description-text">Browse all the images posted by your colleagues, add your own images to the gallery and showcase your company's memories.</p>
                    </div>
            </div>
            <div class="postWrapper">
                <div class="img-box img_5">
                        <i class="fas fa-gamepad"></i>
                    </div>
                    <div class="description-box">
                        <h3 class="description-title">Quiz Room</h3>
                        <p class="description-text">Test your IT knowledge by playing a short quiz and compete with the rest of your teammates.</p>
                    </div>
            </div>
            <div class="postWrapper">
                <div class="img-box img_6">
                        <i class="fas fa-video"></i>
                    </div>
                    <div class="description-box">
                        <h3 class="description-title">Video Chat</h3>
                        <p class="description-text">Immediately start a 1-on-1 video chat with one of your colleagues.</p>
                    </div>
            </div>
            <div class="postWrapper">
                <div class="img-box img_7">
                        <i class="fas fa-comments"></i>
                    </div>
                    <div class="description-box">
                        <h3 class="description-title">Chat rooms</h3>
                        <p class="description-text">Communicate with your colleagues through 1 on 1, or group chats, keep yourself posted with implemented notifications and more.</p>
                    </div>
            </div>
            <div class="postWrapper">
                <div class="img-box img_8">
                        <i class="fas fa-user-circle"></i>
                    </div>
                    <div class="description-box">
                        <h3 class="description-title">Account</h3>
                        <p class="description-text">Control your account, add your personal information and build a unique profile.</p>
                    </div>
            </div>
            <div class="postWrapper">
                <div class="img-box img_9">
                        <i class="fas fa-laptop-house"></i>
                    </div>
                    <div class="description-box">
                        <h3 class="description-title">Remote work</h3>
                        <p class="description-text">Improve the efficiency of your remote work, have it all in one place.</p>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
import { fb, db } from "../../backend/firebase.js";
import Datepicker from "vuejs-datepicker";
//const dateFormat = require("dateformat");
//import firebase from "firebase/app";
//import $ from "jquery";


export default {
  name: "Main",
  components: {
    Datepicker,
  },
  data() {
    return {
        profile: null
    }
  },
  created() {
    var user = fb.auth().currentUser;
    db.collection("profiles").doc(user.uid).get().then(snapshot => {
        if(snapshot.data().action == "register") {
            var rooms = []
            var inbox = []
            db.collection("profiles").doc(user.uid).update({
                inbox: []
            })
            db.collection("rooms").get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    if(doc.data().roomType == "genericroom") {
                        rooms.push(doc.data())
                    }
                });
                rooms.forEach(room => {
                    inbox = [...inbox, {roomId: room.id, roomName: room.ime, messagesCounter: 0}]
                })
                db.collection("profiles").doc(this.profile.id).update({
                    inbox: inbox, action: "login"
                })  
            }); 
        }
    })
  },
  firestore() {
    const user = fb.auth().currentUser;
    return {
      profile: db.collection("profiles").doc(user.uid),
    };
  },
};
</script>

<style scoped src="./dashboard.css">
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
</style>