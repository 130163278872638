<template>
  <div id="NewsBoard">
    <AddNews />
    <PostModal :post="post"/>
    <div id="text-box">
      <div class="newsboard-header-wrapper">
          <h2>Welcome to the <strong>News Board</strong>!</h2>
          <div id="button-box" v-if="profile.role != 'viewer'">
              <i class="fas fa-plus-circle plusButton" data-toggle="modal" data-target="#addTodoModal"></i>
          </div>
      </div>
      <hr>
      <div class="text-box-description">
        <p>Here you can read all the latest news from your company!</p>
      </div>
      <div v-if="posts.length == 0" class="text-box-description">
        <b><p>There are currently no posts added.</p></b>
      </div>
    </div>
    <scroll-loader v-if="loading" :loader-method="created" :loader-enable="loading">
    </scroll-loader>
    <div class="postsWrapper">
      <div class="postWrapper" v-for="(post, index) in posts" v-bind:key="index" data-toggle="modal" data-target="#postModal" @click="openPost(post)">
        <div class="dateWrapper">
          {{ post.createdAt[2] | dateFilter }}
          <div class="dateDescription">
            {{ getDateMonth(post.createdAt[1])}}
            <br>
            <b>{{ post.dayOfTheWeek }}</b>
          </div>
        </div>
        {{ post.title }}
        <br>
        <font style="font-size: 14px; margin-top: 10%; font-weight: 500">{{ post.postedByUser }}</font>
      </div>
    </div>
  </div>
</template>

<script>
import { fb, db } from "../../backend/firebase.js";

export default {
  name: "NewsBoard",
  data() {
    return {
      loading: true,
      tab: "chat",
      profile: {
        name: null,
        surname: null,
        email: null,
      },
      post: {
        title: null,
        description: null,
        postedBy: null,
        postedByUser: null,
        createdAt: null,
      },
      posts: []
    };
  },
  filters: {
    dateFilter: function (value) {
      if (value < 10)  {
        value = value.toString()
        return "0" + value;
      }
      return value;
    }
  },
  created() {
    fetch('https://vof-cloud-backend.herokuapp.com/posts')
    .then(response => response.json())
    .then((data) => {
      if(data) {
        //console.log(data)
        const days = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]
        data.forEach(post => {
          let day = days[new Date(post.createdAt[0], post.createdAt[1] - 1, post.createdAt[2]).getDay()]
          post.dayOfTheWeek = day;
        })
        data.forEach(post => {
          db.collection("profiles").doc(post.postedBy).get().then((snapshot) => {
            if (snapshot) {
              post.postedByUser = snapshot.data().name + " " + snapshot.data().surname;
              this.posts = [...this.posts, post]
              this.loading = false
            }
            else {
              console.log("No data available");
            }
          }).catch((error) => {
            console.error(error);
          });
        })
      }
    }).catch((error) => {
      console.error(error);
    });
  },
  methods: {
    openPost(passedPost) {
      this.post = passedPost;
    },
    getDateMonth(monthNumber) {
      if(monthNumber == 1) return "JANUARY";
      else if (monthNumber == 2) return "FEBRUARY";
      else if (monthNumber == 3) return "MARCH";
      else if (monthNumber == 4) return "APRIL";
      else if (monthNumber == 5) return "MAY";
      else if (monthNumber == 6) return "JUNE";
      else if (monthNumber == 7) return "JULY";
      else if (monthNumber == 8) return "AUGUST";
      else if (monthNumber == 9) return "SEPTEMBER";
      else if (monthNumber == 10) return "OCTOBER";
      else if (monthNumber == 11) return "NOVEMBER";
      else if (monthNumber == 12) return "DECEMBER";
      else return "";
    },
  },
  firestore() {
    const user = fb.auth().currentUser;
    return {
      profile: db.collection("profiles").doc(user.uid),
    };
  },
};
</script>

<style scoped src="./news-board.css">
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
</style>