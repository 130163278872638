<template>
  <div id="ProjectTimeline">
    <AddTicket />
    <EditTicket :ticket="ticket" />

    <!-- Ispis ticketa -->
    <div class="project-timeline-wrapper">
        <br />
        <div id="text-box">
            <div class="project-timeline-header-wrapper">
                <h2>Welcome to the <strong>Project Timeline</strong>!</h2>
                <div id="button-box" v-if="profile.role == 'editor' || profile.role == 'admin'">
                    <i class="fas fa-plus-circle plusButton" data-toggle="modal" data-target="#addTicketModal"></i>
                </div>
            </div>
            <hr>
            <div class="text-box-description">
                <p>Here you can manage all of the tasks of your current project!</p>
            </div>
            <div v-if="tickets.length == 0" class="text-box-description">
                <b><p>There are currently no tickets added.</p></b>
            </div>
        </div>
      
      <div class="table-responsive table-bordered" style="opacity: 0.9;">
        <table class="table text-center table-bordered table-sm">
          <tbody style="backgroundColor: #E8E8E8;">
            <tr>
                <th>
                    <div class="table-responsive tickets-table">
                        <table class="table text-center table-bordered table-sm">
                            <thead class="thead-dark">
                                <tr class="table-field">
                                    <th>To do</th>
                                </tr>
                            </thead>
                            <tbody style="backgroundColor: #DCDCDC;">
                                <tr>
                                    <div v-for="(ticket, index) in tickets.filter(ticket => {return ticket.status == 'To do'})" v-bind:key="index" data-toggle="modal" data-target="#editTicketModal" @click="editTicket(ticket)">
                                        <Ticket :ticket="ticket"/>
                                    </div>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </th>
                <th>
                    <div class="table-responsive tickets-table">
                        <table class="table text-center table-bordered table-sm">
                            <thead class="thead-dark">
                                <tr class="table-field">
                                    <th>On hold</th>
                                </tr>
                            </thead>
                            <tbody style="backgroundColor: #DCDCDC;">
                                <tr>
                                    <div v-for="(ticket, index) in tickets.filter(ticket => {return ticket.status == 'On hold'})" v-bind:key="index" data-toggle="modal" data-target="#editTicketModal" @click="editTicket(ticket)">
                                        <Ticket :ticket="ticket"/>
                                    </div>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </th>
                <th>
                    <div class="table-responsive tickets-table">
                        <table class="table text-center table-bordered table-sm">
                            <thead class="thead-dark">
                                <tr class="table-field">
                                    <th>In progress</th>
                                </tr>
                            </thead>
                            <tbody style="backgroundColor: #DCDCDC;">
                                <tr>
                                    <div v-for="(ticket, index) in tickets.filter(ticket => {return ticket.status == 'In progress'})" v-bind:key="index" data-toggle="modal" data-target="#editTicketModal" @click="editTicket(ticket)">
                                        <Ticket :ticket="ticket"/>
                                    </div>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </th>
                <th>
                    <div class="table-responsive tickets-table">
                        <table class="table text-center table-bordered table-sm">
                            <thead class="thead-dark">
                                <tr class="table-field">
                                    <th >Pending review</th>
                                </tr>
                            </thead>
                            <tbody style="backgroundColor: #DCDCDC;">
                                <tr>
                                    <div v-for="(ticket, index) in tickets.filter(ticket => {return ticket.status == 'Pending review'})" v-bind:key="index" data-toggle="modal" data-target="#editTicketModal" @click="editTicket(ticket)">
                                        <Ticket :ticket="ticket"/>
                                    </div>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </th>
                <th>
                    <div class="table-responsive tickets-table">
                        <table class="table text-center table-bordered table-sm">
                            <thead class="thead-dark">
                                <tr class="table-field">
                                    <th>QA pass</th>
                                </tr>
                            </thead>
                            <tbody style="backgroundColor: #DCDCDC;">
                                <tr>
                                    <div v-for="(ticket, index) in tickets.filter(ticket => {return ticket.status == 'QA pass'})" v-bind:key="index" data-toggle="modal" data-target="#editTicketModal" @click="editTicket(ticket)">
                                        <Ticket :ticket="ticket"/>
                                    </div>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </th>
                <th>
                    <div class="table-responsive tickets-table">
                        <table class="table text-center table-bordered table-sm">
                            <thead class="thead-dark">
                                <tr class="table-field">
                                    <th>Done</th>
                                </tr>
                            </thead>
                            <tbody style="backgroundColor: #DCDCDC;">
                                <tr>
                                    <div v-for="(ticket, index) in tickets.filter(ticket => {return ticket.status == 'Done'})" v-bind:key="index" data-toggle="modal" data-target="#editTicketModal" @click="editTicket(ticket)">
                                        <Ticket :ticket="ticket"/>
                                    </div>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { fb, db } from "../../backend/firebase.js";
export default {
    name: "ProjectTimeline",
    data() {
        return {
            ticket: {
                id: null,
                title: null,
                description: null,
                status: null,
                priority: null,
                deadline: null,
                assignedTo: [],
                postedBy: null,
                time: null
            },
            statusOptions: ["To do", "On hold", "In progress", "Pending review", "QA pass", "Done"],
            priorityOptions: ["Low", "Medium", "High"],
            currentAssignee: null,
            profiles: [],
            tickets: [],
            selectedTicket: null,
            modal: null
        }
    },
    methods: {
        editTicket(ticket) {
            this.ticket = ticket;
        },
    },
    firestore() {
    const user = fb.auth().currentUser;
    return {
        tickets: db.collection("tickets"),
        profile: db.collection("profiles").doc(user.uid),
        profiles: db.collection("profiles")
    };
  },
}
</script>

<style scoped src="./project-timeline.css">
</style>