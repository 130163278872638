<template>
  <div id="PostModal">
    <div id="postModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header modalTitle">
                <h5 class="modal-title" id="exampleModalLabel"><b>{{ this.$props.post.title }}</b></h5>
                <i v-if="profile.role != 'viewer'" class="fas fa-trash delete-icon" @click="deletePost($props.post.id)"></i>
            </div>
            <div class="modal-body modalDescription">
                {{ this.$props.post.description }}
            </div>
            <div class="modal-footer modalFooterText">
                Posted by: {{ this.$props.post.postedByUser}}
            </div>
        </div>
    </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios")
import { fb, db } from "../../backend/firebase.js";

export default {
    name: "PostModal",
    props: ['post'],
    methods: {
        deletePost(postId) {
            Swal.fire({
                title: "Are you sure you want to delete this post?",
                text: "The post will be lost forever.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, I am.",
                cancelButtonText: "No, I am not.",
            }).then((result) => {
                if (result.value) {
                    axios.delete(`https://vof-cloud-backend.herokuapp.com/posts/${postId}`)
                    .then((response) => {
                        $("#postModal").modal("hide");
                        Toast.fire({
                            icon: "success",
                            title: "Post successfully deleted!",
                        });
                    }, (error) => {
                        Toast.fire({
                            icon: "error",
                            title: "There was a problem with deleting the post!",
                        });
                        console.log(error);
                    });
                }
            });
        }
    },
    firestore() {
    const user = fb.auth().currentUser;
    return {
      profile: db.collection("profiles").doc(user.uid),
    };
  },
}
</script>

<style scoped src="./post-modal.css">
</style>
