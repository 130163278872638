import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/app.scss";
import moment from 'moment'
import './registerServiceWorker'

//Firestore setup
import VueFirestore from 'vue-firestore'
import { fb } from './backend/firebase'
Vue.use(VueFirestore, {
  key: 'id',         // the name of the property. Default is '.key'.
  enumerable: true  //  whether it is enumerable or not. Default is true.
})

//jQuery setup
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

//Bootstrap setup
import "bootstrap";
import { BootstrapVue } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue);

//Swal Alert package setup
import Swal from 'sweetalert2'
window.Swal = Swal;
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})
window.Toast = Toast;

//ScrollLoader setup
import ScrollLoader from 'vue-scroll-loader'

import './registerServiceWorker'
Vue.use(ScrollLoader)

//Registered components
Vue.component('Admin', require('./components/Admin/Admin.vue').default);
Vue.component('Home', require('./components/Home/Home.vue').default);
Vue.component('LoginRegister', require('./components/Authentication/LoginRegister.vue').default);
Vue.component('Main', require('./components/Main/Main.vue').default);
Vue.component('RelaxationRoom', require('./components/RelaxationRoom/RelaxationRoom.vue').default);
Vue.component('NewsBoard', require('./components/NewsBoard/NewsBoard.vue').default);
Vue.component('Navbar', require('./components/Navbar/Navbar.vue').default);
Vue.component('AddNews', require('./components/NewsBoard/AddNews.vue').default);
Vue.component('MyProfile', require('./components/MyProfile/MyProfile.vue').default); 
Vue.component('FrontPage', require('./components/Dashboard/Dashboard.vue').default);
Vue.component('Gallery', require('./components/Gallery/Gallery.vue').default);
Vue.component('Quiz', require('./components/Quiz/Quiz.vue').default);
Vue.component('PostModal', require('./components/NewsBoard/PostModal.vue').default);
Vue.component('DirectMessages', require('./components/DirectMessages/DirectMessages.vue').default);
Vue.component('ProjectTimeline', require('./components/ProjectTimeline/ProjectTimeline.vue').default);
Vue.component('AddTicket', require('./components/ProjectTimeline/AddTicket.vue').default);
Vue.component('EditTicket', require('./components/ProjectTimeline/EditTicket.vue').default);
Vue.component('Ticket', require('./components/ProjectTimeline/Ticket.vue').default);
Vue.component('SearchForm', require('./components/RelaxationRoom/SearchForm.vue').default);
Vue.component('SearchResults', require('./components/RelaxationRoom/SearchResults.vue').default);
Vue.component('Pagination', require('./components/RelaxationRoom/Pagination.vue').default);
//Vue.component('MyMessages', require('./components/Deprecated/MyMessages.vue').default); --deprecated
//Vue.component('Register', require('./components/Deprecated/Register.vue').default); --deprecated

//Youtube API filter
Vue.filter('formatDate', function (value) {
  if (!value) return ''
  return moment(value.toString()).format('MM/DD/YYYY hh:mm')
})

//Firebase authentication setup
Vue.config.productionTip = false
let app = '';
fb.auth().onAuthStateChanged(function (user) {
  if (!app) {
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
});


