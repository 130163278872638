<template>
    <div id="Ticket">
        <div class="ticketWrapper">
            {{ ticket.title }}
            <font class="ticketDescription">Posted by: {{ ticket.postedBy.name }} {{ ticket.postedBy.surname }}</font>
        </div>
    </div>
</template>

<script>
export default {
    name: "Ticket",
    props: ["ticket"]
}
</script>

<style scoped src="./ticket.css">
</style>