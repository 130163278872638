import Vue from 'vue'

//Firebase
import { fb } from '../backend/firebase'

//Components
import Admin from '../components/Admin/Admin.vue'
import Home from '../components/Home/Home.vue'
import Main from '../components/Main/Main.vue'
import ProjectTimeline from '../components/ProjectTimeline/ProjectTimeline.vue'
import FrontPage from '../components/Dashboard/Dashboard.vue'
import RelaxationRoom from '../components/RelaxationRoom/RelaxationRoom.vue'
import myProfile from '../components/MyProfile/MyProfile.vue'
//import myMessages from '../components/Deprecated/MyMessages.vue'
import NewsBoard from '../components/NewsBoard/NewsBoard.vue'
import Gallery from '../components/Gallery/Gallery.vue'
import Quiz from '../components/Quiz/Quiz.vue'
import DirectMessages from '../components/DirectMessages/DirectMessages.vue'

//Vue router setup
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },

    {
      path: '/main',
      name: 'Main',
      component: Main,
      meta: { requiresAuth: true, validation: true },
      children: [
        {
          path: 'admin',
          name: 'Admin',
          component: Admin,
        },
        {
          path: 'relaxation',
          name: 'RelaxationRoom',
          component: RelaxationRoom,
        },
        {
          path: 'projecttimeline',
          name: 'ProjectTimeline',
          component: ProjectTimeline,
        },
        {
          path: 'frontpage',
          name: 'FrontPage',
          component: FrontPage,
        },
        {
          path: 'myprofile',
          name: 'myProfile',
          component: myProfile,
        },
        /*
        {
          path: 'mymessages',
          name: 'myMessages',
          component: myMessages,
        },*/
        {
          path: 'newsboard',
          name: 'newsboard',
          component: NewsBoard,
        },
        {
          path: 'gallery',
          name: 'gallery',
          component: Gallery,
        },
        {
          path: 'quiz',
          name: 'quiz',
          component: Quiz,
        },
        {
          path: 'directmessages',
          name: 'DirectMessages',
          component: DirectMessages,
        }
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {

  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const currentUser = fb.auth().currentUser
  if (requiresAuth && !currentUser) {
    next('/')
  } else if (requiresAuth && currentUser) {
    next()
  } else {
    next()
  }
})

export default router
