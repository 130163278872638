<template>
  <div id="Navbar">
    <nav
      class="navbar navbar-expand-lg navbar-light w-100"
      style="background-color: rgba(0, 0, 0, 0.8); z-index: -10"
    >
      <div  class="container">
        <a class="navbar-brand text-light" href="#"
          ><digital-clock :blink="true" class="text-light ml-5"
        /></a>
        <span class="text-light ml-2" style="cursor: pointer">{{ date }}</span>
        <span class="ml-5" style="cursor: pointer; color: rgba(96, 180, 213)"><B>VirtualOffice</B></span>
        <button
          class="navbar-toggler text-light"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          style="
            background: none !important;
            border: none !important;
            outline: none !important;
            color: white !important;
          "
        >
        </button>
      </div>
    </nav>
  </div>
</template>

<script>
import DigitalClock from "vue-digital-clock";
import { fb, db } from "../../backend/firebase";
import Datepicker from "vuejs-datepicker";
const dateFormat = require("dateformat");

export default {
  name: "Navbar",
  data() {
    return {
      date: dateFormat(new Date(), "fullDate")
    }
  },
  components: {
    DigitalClock, Datepicker
  },
  methods: {
    goHome() {
      this.$router.replace("/");
    },
    signOut() {
      fb.auth()
        .signOut()
        .then(() => {
          this.$router.replace("/");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped src="./navbar.css">
</style>