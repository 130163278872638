import { render, staticRenderFns } from "./Main.vue?vue&type=template&id=65a69638&scoped=true&"
import script from "./Main.vue?vue&type=script&lang=js&"
export * from "./Main.vue?vue&type=script&lang=js&"
import style0 from "./main.css?vue&type=style&index=0&id=65a69638&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65a69638",
  null
  
)

export default component.exports