<template>
  <div id="DirectMessages">
    <div id="text-box">
      <h2><strong>Direct Messages panel</strong></h2>
      <hr>
      <div class="text-box-description">
        <p>Start a conversation with one of your colleagues.</p>
      </div>
    </div>
    <ul class="user-list list-group">
        <li class="user-item list-group-item" v-for="(user, index) in profiles" v-bind:key="index" v-show="profile.id != user.id" @click="startConversation(user.id)"> {{ user.name }} {{ user.surname }} </li>
    </ul>
  </div>
</template>

<script>
import { fb, db } from "../../backend/firebase.js";

export default {
  name: "DirectMessages",
  data() {
    return {
      users: [],
      rooms: [],
      selectedUser: null,
      message: null,
      newRoom: {
        ime: "",
        id: null,
        sender: null,
        recipient: null,
        roomType: null,
        messagesCounter: 0
      },
    };
  },
  methods: {
    generateID(length) {
      var ret = "";
      while (ret.length < length) {
        ret += Math.random().toString(16).substring(2);
      }
      return ret.substring(0, length);
    },
    startConversation(userId) {
      db.collection("profiles").doc(userId).get().then((snapshot) => {
        if (snapshot) {
          this.selectedUser = snapshot.data();
          Swal.fire({
            title: `Are you sure you want to start a conversation with ${this.selectedUser.name} ${this.selectedUser.surname}?`,
            input: 'text',
            inputLabel: 'Your message',
            inputValue: this.message,
            confirmButtonText: "Send",
            showCancelButton: true,
            inputValidator: (value) => {
              if (!value) {
                return 'You need to write something!'
              }
              //Id sobe
              var id = this.generateID(26);
              //Naziv sobe
              let usersIds = [this.profile.id, this.selectedUser.id];
              usersIds.sort();
              let roomName = "";
              usersIds.forEach(id => {
                roomName += id;
              })
              this.newRoom.ime = roomName;
              //Provjera originalnosti sobe
              let chosenExistingRoom = false;
              if (this.newRoom.ime.toLowerCase() == "Relaxation Room".toLowerCase()) {
                chosenExistingRoom = true;
              }
              if (!chosenExistingRoom) {
                this.rooms.forEach((myroom) => {
                  if (myroom.ime == this.newRoom.ime) {
                    chosenExistingRoom = true;
                  }
                });
              }
              if (chosenExistingRoom) {
                alert("Room already exists!");
              } else {
                //Pravljenje sobe
                this.newRoom.id = id;
                this.newRoom.sender = this.profile;
                this.newRoom.recipient = this.selectedUser;
                this.newRoom.roomType = "directmessages";
                this.newRoom.messagesCounter = 0;
                db.collection("rooms").doc(id).set(this.newRoom);
                db.collection("profiles").doc(this.newRoom.sender.id).update({
                  inbox: [...this.newRoom.sender.inbox, {roomId: this.newRoom.id, roomName: this.newRoom.ime, messagesCounter: 0}]
                })
                db.collection("profiles").doc(this.newRoom.recipient.id).update({
                  inbox: [...this.newRoom.recipient.inbox, {roomId: this.newRoom.id, roomName: this.newRoom.ime, messagesCounter: 0}]
                })
              }
            }
          })
        }
        else {
          console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
      });
    },
  },
  
  firestore() {
    const user = fb.auth().currentUser;
    return {
      profile: db.collection("profiles").doc(user.uid), 
      profiles: db.collection("profiles"),
      rooms: db.collection("rooms"),
    };
  },
};
</script>

<style scoped src="./direct-messages.css">
</style>